/**
Represents the overall container for the call bubble component. It is a flexible container that displays its content in a column format and aligns its items centrally.
*/
.cometchat-call-bubble {
    display: flex;
    max-width: 240px;
    width: 240px;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    background: var(--cometchat-primary-color);
    border-radius: var(--cometchat-radius-3, 12px);
    background: inherit;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

/**
Defines the main body of the call bubble,
organizing its child elements in a flexible layout and aligning them appropriately.
*/
.cometchat-call-bubble__body {
    display: flex;
    padding: var(--cometchat-padding-3, 12px);
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}

/**
Represents the title area of the call bubble,
ensuring the text is constrained to a single line and truncates overflowed text with ellipsis.
*/
.cometchat-call-bubble__body-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;

    text-overflow: ellipsis;
    font: var(--cometchat-font-body-medium);
    font-style: normal;
}

/**
Specifies the icon within the call bubble,
typically an SVG used to visually represent a type of call (e.g., video or voice).
*/
.cometchat-call-bubble__icon-wrapper-icon {
    mask: url("../../assets/incoming_video.svg") center center no-repeat;
    -webkit-mask: url("../../assets/incoming_video.svg") center center no-repeat;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cometchat-primary-color);
    -webkit-mask-size: contain;
    mask-size: contain;
}

/**
Acts as the wrapper for the call bubble icon,
setting its size and background with a rounded appearance.
*/
.cometchat-call-bubble__icon-wrapper {
    display: flex;
    width: 40px;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-static-white);
    height: 40px;

    padding: var(--cometchat-padding-2);
    justify-content: center;
    align-items: center;
}

/**
Represents the subtitle text within the call bubble,
ensuring the text is truncated with ellipsis to fit within the given space.
*/
.cometchat-call-bubble__body-content-subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--cometchat-font-caption1-regular);
    font-style: normal;
}

/**
Defines the styling of the subtitle date within the body content of the call bubble
*/
.cometchat-call-bubble__body-content-subtitle .cometchat-date {
    padding: 0px;
    color: inherit;
    font: inherit;
  }

/**
Provides additional content styling for the body of the call bubble, aligning the content text to the left.
*/
.cometchat-call-bubble__body-content {
    text-align: left;
}


/**
 Defines the button at the bottom of the call bubble,
 making it fully responsive in width and controlling its appearance with inherited styles.
*/
.cometchat-call-bubble__button {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    border: none;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    width: 100%;
    background: inherit;
    border-radius: 0px 0px var(--cometchat-radius-radius_2, 8px) var(--cometchat-radius-radius_2, 8px);
    font: var(--cometchat-font-body-medium);
    font-style: normal;
    cursor: pointer;
}

/**
Represents the visual styling for an incoming call bubble,
providing a background color specific to incoming calls.
*/
.cometchat-call-bubble-incoming {
    background: var(--cometchat-neutral-color-300);
}

/**
Represents the visual styling for an outgoing call bubble,
providing a background color specific to outgoing calls.
*/
.cometchat-call-bubble-outgoing {
    background: var(--cometchat-primary-color);
}

/**
Adjusts the subtitle text color within an incoming call bubble.
*/
.cometchat-call-bubble-incoming .cometchat-call-bubble__body-content-subtitle {
    color: var(--cometchat-neutral-color-600);
}

.cometchat-call-bubble__body-content-title {
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-medium);
}

/**
 Adjusts the title text color for the body content within an incoming call bubble.
*/
.cometchat-call-bubble-incoming .cometchat-call-bubble__body-content-title {
    color: var(--cometchat-neutral-color-900);
}

/**
Adjusts the subtitle text color within an outgoing call bubble.
*/
.cometchat-call-bubble-outgoing .cometchat-call-bubble__body-content-subtitle {
    color: var(--cometchat-static-white);
}

/**
 Adjusts the title text color for the body content within an outgoing call bubble.
*/
.cometchat-call-bubble-outgoing .cometchat-call-bubble__body-content-title {
    color: var(--cometchat-static-white);
}

/**
Defines the styling of the button for incoming call bubbles,
adding a border and setting the button text color.
*/
.cometchat-call-bubble-incoming .cometchat-call-bubble__button {
    border-top: 1px solid var(--cometchat-border-color-dark);
    color: var(--cometchat-neutral-color-900);
}

/**
Defines the styling of the button for outgoing call bubbles,
including a top border and button text color.
*/
.cometchat-call-bubble-outgoing .cometchat-call-bubble__button {
    border-top: 1px solid var(--cometchat-extended-primary-color-800);
    color: var(--cometchat-static-white);
}