.cometchat-media-recorder__recording,
.cometchat-media-recorder__recorded {
    display: flex;
    width: 260px;
    height: inherit;
    padding: var(--cometchat-padding-5, 20px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-5, 20px);
    border-radius: var(--cometchat-radius-4, 16px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-media-recorder__recording{
    justify-content: space-between;
}
.cometchat-media-recorder {
    width: max-content;
}

.cometchat-media-recorder .cometchat-audio-bubble {
    padding-bottom: var(--cometchat-padding-2);
    max-width: 100%;
    width: 100%;
}

.cometchat-media-recorder .cometchat-audio-bubble .cometchat-audio-bubble__tail-view {
    display: none;
}



.cometchat-media-recorder .cometchat-audio-bubble .cometchat-audio-bubble__body {
    flex-direction: row;
    gap: var(--cometchat-padding-2);
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
}

.cometchat-media-recorder__recording-control,
.cometchat-media-recorder__recorded-control {
    display: flex;
    align-items: center;
    gap: 20px;
}

.cometchat-media-recorder__recording-preview {
    display: flex;
    width: 120px;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
}

.cometchat-media-recorder__recorded-preview {
    display: flex;
    width: 260px;
    padding: var(--cometchat-padding-2, 8px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    border-radius: var(--cometchat-radius-3, 12px);
    background: var(--cometchat-primary-color, #6852D6);
}

.cometchat-media-recorder__recording-control-delete,
.cometchat-media-recorder__recorded-control-delete,
.cometchat-media-recorder__recording-control-stop,
.cometchat-media-recorder__recorded-control-record {
    display: flex;
    width: 40px;
    cursor: pointer;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-background-color-01, #FFF);
    border-radius: var(--cometchat-radius-max, 1000px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.cometchat-media-recorder__recording-control-stop-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    mask: url("../../assets/stop.svg") center center no-repeat;
    -webkit-mask: url("../../assets/stop.svg") center center no-repeat;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recording-control-delete-icon,
.cometchat-media-recorder__recorded-control-delete-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    -webkit-mask: url("../../assets/delete.svg") center center no-repeat;
    mask: url("../../assets/delete.svg") center center no-repeat;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recorded-control-record-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    -webkit-mask: url("../../assets/recording_resume.svg") center center no-repeat;
    mask: url("../../assets/recording_resume.svg") center center no-repeat;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recording-control-record,
.cometchat-media-recorder__recording-control-pause,
.cometchat-media-recorder__recorded-control-send {
    display: flex;
    cursor: pointer;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.cometchat-media-recorder__recording-control-error>.cometchat-media-recorder__recording-control-record-icon,
.cometchat-media-recorder__recording-control-record-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recording-control-record-icon {
    -webkit-mask: url("../../assets/recording_resume.svg") center center no-repeat;
    mask: url("../../assets/recording_resume.svg") center center no-repeat;
    background: var(--cometchat-error-color, #F44649);
}

.cometchat-media-recorder__recording-control-error>.cometchat-media-recorder__recording-control-record-icon {
    -webkit-mask: url("../../assets/mic_off.svg") center center no-repeat;
    mask: url("../../assets/mic_off.svg") center center no-repeat;
    background: var(--cometchat-text-color-tertiary, #A1A1A1);
}

.cometchat-media-recorder__recording-control-error {
    pointer-events: none;
}
.cometchat-media-recorder__recording-control-pause-icon {
    -webkit-mask: url("../../assets/pause.svg") center center no-repeat;
    mask: url("../../assets/pause.svg") center center no-repeat;
    background: var(--cometchat-error-color, #F44649);
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recorded-control-send-icon {
    -webkit-mask: url("../../assets/send_fill.svg") center center no-repeat;
    mask: url("../../assets/send_fill.svg") center center no-repeat;
    background: var(--cometchat-icon-color-highlight, #6852D6);
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recording-preview-recording,
.cometchat-media-recorder__recording-preview-paused {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-icon-color-highlight, #6852D6);
}

.cometchat-media-recorder__recording-preview-recording-icon,
.cometchat-media-recorder__recording-preview-paused-icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    background: var(--cometchat-icon-color-white, #FFFFFF);
    -webkit-mask: url("../../assets/recording_icon.svg") center center no-repeat;
    mask: url("../../assets/recording_icon.svg") center center no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recording-preview-disabled {
    opacity: 0.2;
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-icon-color-highlight, #6852D6);
}

.cometchat-media-recorder__recording-preview-disabled-icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    background: var(--cometchat-icon-color-white, #FFFFFF);
    -webkit-mask: url("../../assets/recording_icon.svg") center center no-repeat;
    mask: url("../../assets/recording_icon.svg") center center no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
}

.cometchat-media-recorder__recording-preview-duration {
    align-self: stretch;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading4-regular, 400 16px Roboto);
    line-height: 120%;
}
.cometchat-media-recorder__error {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--cometchat-background-color-02, #FAFAFA);
    border-radius: var(--cometchat-radius-4) var(--cometchat-radius-4) 0px 0px;
    padding: var(--cometchat-padding-2);
    gap: var(--cometchat-padding-2);
    width: 260px;
    position: absolute;
    z-index: 2;
    bottom: 100%;
}

.cometchat-media-recorder__error-content {
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding-1);
}

.cometchat-media-recorder__error-content-title {
    font: var(--cometchat-font-caption1-bold);
}

.cometchat-media-recorder__error-content-subtitle {
    font: var(--cometchat-font-caption1-regular);

}

.cometchat-media-recorder__error-content-title,
.cometchat-media-recorder__error-content-subtitle {
    text-align: left;
    color: var(--cometchat-text-color-secondary, #727272);

}

.cometchat-media-recorder__error-icon-wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-error-color);
    border-radius: var(--cometchat-radius-max);
}

.cometchat-media-recorder__error-icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: var(--cometchat-static-white);
    -webkit-mask: url("../../assets/error_info.svg") center center no-repeat;
    mask: url("../../assets/error_info.svg") center center no-repeat;
    -webkit-mask-size: contain;
    transform: rotate(180deg);
    mask-size: contain;
}