.cometchat-list-item {
    display: flex;
    width: 100%;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    background: var(--cometchat-background-color-01, #FFF);
    box-sizing: border-box;
}

.cometchat-list-item:hover {
    background: var(--cometchat-white-hover, #FAFAFA);
}

.cometchat-list-item:active {
    background: var(--cometchat-neutral-color-300, #E8E8E8);
}

.cometchat-list-item__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    overflow: hidden;
}

.cometchat-list-item__body-title {
    height: 19px;
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-heading4-medium, 500 16px Roboto);
    line-height: 120%;
    margin-right: var(--cometchat-margin-1);
    width: 100%;

}

.cometchat-list-item__title-container {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-1, 4px);
    flex: 1 0 0;
    overflow: hidden;
    width: 100%;
    text-align: left;
}

.cometchat-list-item__menu-view,
.cometchat-list-item__trailing-view {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: flex-end;
    align-items: center;
}

.cometchat-list-item__trailing-view-hidden {
    display: none;
}

.cometchat-list-item__leading-view {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 9px 9px 9px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max, 1000px);
}

.cometchat-list-item__body-subtitle {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cometchat-list-item__status {
    display: none;
}

.cometchat-list-item__status-icon_right {
    border-radius: 16px;
    width: 10px;
    height: 10px;
    border: none;
    position: relative;
    bottom: 20%;
    margin-left: 20px;
}

.cometchat-list-item__divider {
    height: 1px;
    width: 100%;
    background: grey;
}