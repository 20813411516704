.cometchat-call-button {
    display: flex;
    align-items: center;
    gap: var(--cometchat-padding-4, 16px);
    height: fit-content;
    width: fit-content;
}

.cometchat-call-button__voice,
.cometchat-call-button__video {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cometchat-call-button .cometchat-button {
    width: 24px;
    height: 24px;
    padding: 0px;
    gap: 0px;
    background: var(--cometchat-background-color-01, #FFFFFF);
}

.cometchat-call-button .cometchat-button__icon {
    background: var(--cometchat-icon-color-primary, #141414);
}

.cometchat-outgoing-call__backdrop {
    display: flex;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.cometchat-call-button .cometchat-button:hover {
    background: transparent;
}

.cometchat-call-button .cometchat-button:active {
    background: transparent;
}