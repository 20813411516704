.cometchat-create-poll {
  display: flex;
  width: 420px;
  height: 480px;
  flex-direction: column;
  align-items: center;
  gap: var(--cometchat-padding-2, 8px);
  flex-shrink: 0;
  align-self: stretch;
  background-color: var(--cometchat-background-color-01, #FFFFFF);
  border-radius: var(--cometchat-radius-3, 12px);
  overflow: hidden;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  position: relative;
}

.cometchat-create-poll__header {
  padding: var(--cometchat-radius-3, 12px);
  border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}


.cometchat-create-poll__header-title {
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  font: var(--cometchat-font-heading2-bold);
  font-style: normal;
  line-height: 120%;
}

.cometchat-create-poll__header-close-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  mask-repeat: no-repeat;
  background-color: var(--cometchat-icon-color-primary, #141414);
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask: url("../../assets/close.svg") center center no-repeat;
  mask: url("../../assets/close.svg") center center no-repeat;
  border: none;
  cursor: pointer;
}

.cometchat-create-poll__body {
  padding: var(--cometchat-padding-4, 16px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  overflow: hidden;
}

.cometchat-create-poll__body-question {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-1, 4px);
}

.cometchat-create-poll__body-question-title {
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-heading4-medium);
  font-style: normal;
  line-height: 120%;
}


.cometchat-create-poll__body-question-input {
  display: flex;
  height: 36px;
  padding: var(--cometchat-padding-2, 8px);
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
  flex: 1 0 0;

  border-radius: var(--cometchat-radius-2, 8px);
  border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  background: var(--cometchat-background-color-01, #FFF);
  flex: 1 0 0;
  width: 100%;
  min-width: 90px;
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  outline: none;
  /* border: none; */
}

.cometchat-create-poll__body-options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: var(--cometchat-padding-5, 20px);
  gap: var(--cometchat-padding-2, 8px);
  margin-bottom: var(--cometchat-padding-2, 8px);
  overflow: hidden;
  max-height: 64%;
}

.cometchat-create-poll__body-options-title {
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-heading4-medium);
  font-style: normal;
}

.cometchat-create-poll__body-options {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--cometchat-padding-2, 8px);
  scrollbar-width: none;
  overflow-y: scroll;
}

.cometchat-create-poll__body-option-input {
  display: flex;
  height: 36px;
  padding: var(--cometchat-padding-2, 8px);
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  background: var(--cometchat-background-color-01, #FFF);
  color: var(--cometchat-text-color-primary, #141414);
  width: 100%;
  min-width: 90px;
  flex: 1 0 0;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  outline: none;
}

.cometchat-create-poll__body-options-add-button {
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: var(--cometchat-text-color-highlight, #6852D6);
  font: var(--cometchat-font-caption1-medium);
  font-style: normal;
  line-height: 120%;
  padding-bottom: var(--cometchat-padding-2);
}

.cometchat-create-poll__body-option-remove-button {
  -webkit-mask: url("../../assets/close.svg") center center no-repeat;
  mask: url("../../assets/close.svg") center center no-repeat;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cometchat-icon-color-secondary, #A1A1A1);
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  cursor: pointer;
}

.cometchat-create-poll__button {
  width: 388px;
  height: 40px;
}


.cometchat-create-poll__button>.cometchat{
  height: 100%;
  width: 100%;
}
.cometchat-create-poll__button .cometchat-button{
  border: none;
  cursor: pointer;
  text-align: left;
  color: var(--cometchat-primary-button-icon, #FFF);
  font: var(--cometchat-font-button-medium);
  font-style: normal;
  line-height: 120%;
  background: var(--cometchat-primary-button-background, #6852D6);
  border-radius: var(--cometchat-radius-2, 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.cometchat-create-poll__button-disabled .cometchat-button {
  background: var(--cometchat-background-color-04, #E8E8E8);
  color: var(--cometchat-static-white, #FFF);
  pointer-events: none;
}

.cometchat-create-poll__body-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
}

.cometchat-create-poll-backdrop {
  background: #141414CC;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cometchat-create-poll__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  border-top: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px) var(--cometchat-padding-5) var(--cometchat-padding-4, 16px);

}

.cometchat-create-poll__error-icon {
  -webkit-mask: url("../../assets/error_icon.svg") center center no-repeat;
  mask: url("../../assets/error_icon.svg") center center no-repeat;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cometchat-error-color, #F44649);
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  cursor: pointer;
}

.cometchat-create-poll__error {
  display: flex;
  padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-2, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
  align-self: stretch;
  border-radius: var(--cometchat-radius-2, 8px);
  background: rgba(244, 70, 73, 0.10);
}

.cometchat-create-poll__error-text{
  color: var(--cometchat-error-color, #F44649);
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-create-poll__loading-icon {
  -webkit-mask: url("../../assets/loading_icon.svg") center center no-repeat;
  mask: url("../../assets/loading_icon.svg") center center no-repeat;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cometchat-static-white, #FFF);
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  cursor: pointer;
}
.cometchat-create-poll__body-options-add-button-disabled{
  color: var(--cometchat-text-color-disabled, #DCDCDC);
  font: var(--cometchat-font-caption1-medium);
}