.cometchat-conversation-summary__wrapper {
  width: 100%;
  height: 100%;
  padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-2, 8px);
}

.cometchat-conversation-summary {
  display: flex;
  height: 100%;
  width: 100%;
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(--cometchat-background-color-01, #FFF);
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 2%), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-conversation-summary__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.cometchat-conversation-summary__header-title {
  overflow: hidden;
  color: var(--cometchat-text-color-primary, #141414);
  text-overflow: ellipsis;
  font: var(--cometchat-font-body-medium);
  font-style: normal;
}

.cometchat-conversation-summary__header-close-button {
  mask: url("../../assets/close.svg");
  -webkit-mask: url("../../assets/close.svg");
  background-color: var(--cometchat-icon-color-primary, #141414);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.cometchat-conversation-summary__body {
  width: 100%;
  flex: 1 0 0;
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  text-align: left;
}

.cometchat-conversation-summary__shimmer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-conversation-summary__shimmer-item {
  width: 100%;
  display: flex;
  height: 14px;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
  align-items: center;
  align-content: center;
  gap: 4px var(--cometchat-padding-1, 4px);
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: var(--cometchat-radius-max, 1000px);
  background: var(--cometchat-shimmer-gradient-color, linear-gradient(90deg, #E0E0E0 0%, #EEE 100%));
  background-size: 800px 104px;
  /* Increase background size for smooth animation */
  animation: shimmerAnimation 1.5s infinite linear;
  /* Add animation properties */
}

.cometchat-conversation-summary__shimmer .cometchat-conversation-summary__shimmer-item:last-child {
  width: 70%;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.cometchat-conversation-summary__shimmer-item {
  background: var(--cometchat-shimmer-gradient-color, linear-gradient(90deg, #E0E0E0 0%, #EEE 100%));
  background-size: 800px 104px;
  /* Consistent background size for animation */
  animation: shimmerAnimation 1.5s infinite linear;
  /* Apply shimmer animation */
}


.cometchat-conversation-summary__error-view,
.cometchat-conversation-summary__empty-view {
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}