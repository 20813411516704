.cometchat-group-members {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  flex-shrink: 0;
  background: var(--cometchat-background-color-01, #fff);
}

.cometchat-group-members__backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}


.cometchat-group-members .cometchat-list__body::-webkit-scrollbar {
  background: transparent;
  height: 0px;
  width: 0px;
}

.cometchat-group-members .cometchat-list__body::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 8px;
}

.cometchat-group-members .cometchat-group-members__trailing-view-options {
  display: flex;
  padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-5, 20px);
  border-radius: var(--cometchat-radius-max, 1000px);
  background: var(--cometchat-extended-primary-color-100, #edeafa);
  overflow: hidden;
  color: var(--cometchat-text-color-highlight, #6852d6);
  text-overflow: ellipsis;
  font: var(--cometchat-font-caption1-regular);
  font-style: normal;
  text-transform: capitalize;
}

.cometchat-group-members .cometchat-group-members__trailing-view-options-admin {
  border: 1px solid var(--cometchat-border-color-highlight, #6852D6);
}

.cometchat-group-members .cometchat-group-members__trailing-view-options-owner {
  color: var(--cometchat-static-white, #F9F8FD);
  background: var(--cometchat-primary-color, #6852D6);
}

.cometchat-group-members .cometchat-group-members__trailing-view-options-participant {
  display: none;
}

.cometchat-group-members .cometchat-list-item__trailing-view {
  width: auto;
  height: fit-content;
}

.cometchat-group-members .cometchat-menu-list__sub-menu-list-item-icon {
  display: none;
}

.cometchat-group-members .cometchat-menu-list__sub-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  height: 16px;
  width: 16px;
  -webkit-mask: url("../../assets/dropdown-arrow.svg") center center no-repeat;
  mask: url("../../assets/dropdown-arrow.svg") center center no-repeat;
  background: var(--cometchat-icon-color-secondary, #A1A1A1);
}

.cometchat-group-members .cometchat-menu-list__sub-menu-list {
  border-radius: var(--cometchat-radius-2, 8px);
  border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  background: var(--cometchat-background-color-01, #FFF);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-group-members .cometchat-menu-list__sub-menu-list :hover {
  background: var(--cometchat-background-color-02, #fafafa);
}

.cometchat-group-members__empty-state-view {
  display: flex;
  flex-direction: column;
}

.cometchat-group-members__empty-state-view,
.cometchat-group-members__error-state-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: center;
  background: var(--cometchat-background-color-01, #fff);
}

.cometchat-group-members__empty-state-view-body,
.cometchat-group-members__error-state-view-body {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding, 2px);
}

.cometchat-group-members__empty-state-view-body-title,
.cometchat-group-members__error-state-view-body-title {
  width: 280px;
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  font: var(--cometchat-font-heading3-bold);
  font-style: normal;
}

.cometchat-group-members__empty-state-view-body-description,
.cometchat-group-members__error-state-view-body-description {
  width: 280px;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-group-members__shimmer {
  width: 100%;
  height: 100%;
  background: var(--cometchat-background-color-01, #fff);
}

.cometchat-group-members__shimmer-item {
  width: 100%;
  height: 72px;
  display: flex;
  width: 100%;
  min-width: 240px;
  max-width: 1440px;
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  align-items: center;
  gap: 12px;
}

.cometchat-group-members__shimmer-item-avatar {
  display: flex;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: var(--cometchat-radius-max, 1000px);
  background: var(--cometchat-shimmer-gradient-color,
      linear-gradient(90deg, #e0e0e0 0%, #eee 100%));
}

.cometchat-group-members__shimmer-item-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-group-members__shimmer-item-title {
  width: 50%;
  height: 22px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(--cometchat-shimmer-gradient-color,
      linear-gradient(90deg, #e0e0e0 0%, #eee 100%));
  animation: shimmerAnimation 1.5s infinite linear;
}

.cometchat-group-members__shimmer-item-body-subtitle {
  width: 25%;
  height: 12px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(--cometchat-shimmer-gradient-color,
      linear-gradient(90deg, #e0e0e0 0%, #eee 100%));
  animation: shimmerAnimation 1.5s infinite linear;
}

.cometchat-group-members__list-item-online .cometchat-list-item__status {
  width: 14px;
  height: 14px;
  margin-right: -12px;
  position: relative;
  top: 20px;
  right: 15px;
  border-radius: var(--cometchat-radius-max, 1000px);
  background: var(--cometchat-success-color, #09c26f);
  min-width: 14px;
  border: 2px solid var(--cometchat-neutral-color-50, #ffffff);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cometchat-group-members .cometchat-menu-list__sub-menu {
  background: none;
  box-shadow: none;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}