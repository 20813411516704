/* Main container for the list component, taking full width and height */
.cometchat-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
/* Title section of the header, containing text or other elements */
.cometchat-list__header-title {
    display: flex;
    height: fit-content;
    min-height: 64px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    text-align: left;
    overflow: hidden;
    color: var(--cometchat-text-color-primary);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading1-bold);
    font-style: normal;
    width: 100%;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    gap: var(--cometchat-padding-3, 12px);
    flex: 1 0 0;
    align-self: stretch;
}

/* Header section of the list, containing title and possibly other elements */
.cometchat-list__header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: var(--cometchat-background-color-01);
    border-right: 1px solid var(--cometchat-border-color-light);
    border-bottom: 1px solid var(--cometchat-border-color-light);
}


/* Search bar section in the header */
.cometchat-list__header-search-bar {
    display: flex;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    align-items: flex-start;
    align-self: stretch;
    width: 100%;

}

/* Body section of the list, contains the scrollable content */
.cometchat-list__body {
    overflow: hidden auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid var(--cometchat-border-color-light)
}

/* Styles for loading, error, and empty views, taking up the remaining space */
.cometchat-list__loading-view,
.cometchat-list__error-view,
.cometchat-list__empty-view {
    height: calc(100% - 1px);
    overflow: hidden;
    width: 100%;
}

/* Section header text styling */
.cometchat-list__section-header {
    color: var(--cometchat-primary-color, #6852D6);
    font: var(--cometchat-font-heading4-medium);
    font-style: normal;
    text-align: left;
}

/* Section container, holds section items */
.cometchat-list__section {
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px) 0 var(--cometchat-padding-4, 16px);
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
}

.cometchat-list__body::-webkit-scrollbar,
.cometchat-list__body::-webkit-scrollbar-thumb {
    display: none;
}

/* Wrapper for each item in the list */
.cometchat-list__item-wrapper {
    display: flex;
    flex-direction: column;
}