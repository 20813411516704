
.cometchat-conversations {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  flex-shrink: 0;
  background: var(--cometchat-background-color-01, #FFF);
  position: relative;
}

.cometchat-conversations .cometchat-list__body>div:not([class]):first-child {
  display: none;
}


.cometchat-conversations .cometchat-list-item {
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  cursor: pointer;
} 

.cometchat-conversations .cometchat-list-item__body {
  padding: 0px;
  min-height: 43px;
}

.cometchat-conversations .cometchat-list-item__trailing-view{
  width: auto;
  height: 40px;
}

.cometchat-conversations .cometchat-date {
  padding: 0px;
  font: var(--cometchat-font-caption1-regular);
}

.cometchat-conversations__subtitle-receipts {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  align-self: center;  
  background:var(--cometchat-icon-color-secondary, #A1A1A1);
  flex-shrink: 0;
}


.cometchat-conversations__subtitle-receipts-sent{
  -webkit-mask: url("../../assets/conversations_sent.svg") center center no-repeat;
  mask: url("../../assets/conversations_sent.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-receipts-delivered{
  -webkit-mask: url("../../assets/conversations_delivered.svg") center center no-repeat;
  mask: url("../../assets/conversations_delivered.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}


.cometchat-conversations__subtitle-receipts-read{
  -webkit-mask: url("../../assets/conversations_read.svg") center center no-repeat;
  mask: url("../../assets/conversations_read.svg") center center no-repeat;
  background: var(--cometchat-message-seen-color,#56E8A7);
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-text-wrapper {
  display: flex;
  gap: var(--cometchat-padding, 2px);
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cometchat-conversations__subtitle-text-sender {
  overflow: hidden;
  color: var(--cometchat-text-color-secondary, #727272);
  text-overflow: ellipsis;
  font: var(--cometchat-font-body-medium);
  font-style: normal;
  flex-shrink:0;
 }

.cometchat-conversations__subtitle-text{
  overflow: hidden;
  color: var(--cometchat-text-color-secondary, #727272);
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  width: 100%; 
}

.cometchat-conversations__subtitle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  background: var(--cometchat-icon-color-secondary, #A1A1A1);
  height: 16px;
  width: 16px;
  flex-shrink: 0;
}

.cometchat-conversations__subtitle-icon-incoming-audio-call{
  -webkit-mask: url("../../assets/conversations_missed-voice-call.svg") center center no-repeat;
  mask: url("../../assets/conversations_missed-voice-call.svg") center center no-repeat;
  background-color: var(--cometchat-error-color,#F44649);
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-incoming-video-call{
  -webkit-mask: url("../../assets/conversations_missed-video-call.svg") center center no-repeat;
  mask: url("../../assets/conversations_missed-video-call.svg") center center no-repeat;
  background-color: var(--cometchat-error-color,#F44649);
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-outgoing-audio-call{
  -webkit-mask: url("../../assets/conversations_outgoing-voice-call.svg") center center no-repeat;
  mask: url("../../assets/conversations_outgoing-voice-call.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;

}

.cometchat-conversations__subtitle-icon-outgoing-video-call{
  -webkit-mask: url("../../assets/conversations_outgoing-video-call.svg") center center no-repeat;
  mask: url("../../assets/conversations_outgoing-video-call.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}


.cometchat-conversations__subtitle-icon-image{
  -webkit-mask: url("../../assets/conversations_image-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_image-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-file{
  -webkit-mask: url("../../assets/conversations_file-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_file-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-video{
  -webkit-mask: url("../../assets/conversations_video-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_video-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-audio{
  -webkit-mask: url("../../assets/conversations_audio-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_audio-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-poll{
  -webkit-mask: url("../../assets/conversations_poll-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_poll-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}


.cometchat-conversations__subtitle-icon-sticker{
  -webkit-mask: url("../../assets/conversations_sticker-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_sticker-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}


.cometchat-conversations__subtitle-icon-collaborative-document{
  -webkit-mask: url("../../assets/conversations_collaborative-document-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_collaborative-document-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}


.cometchat-conversations__subtitle-icon-collaborative-whiteboard{
  -webkit-mask: url("../../assets/conversations_collaborative-whiteboard-message.svg") center center no-repeat;
  mask: url("../../assets/conversations_collaborative-whiteboard-message.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}
.cometchat-conversations__subtitle-icon-deleted{
  -webkit-mask: url("../../assets/conversations_delete.svg") center center no-repeat;
  mask: url("../../assets/conversations_delete.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-link{
  -webkit-mask: url("../../assets/conversations_link.svg") center center no-repeat;
  mask: url("../../assets/conversations_link.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-thread {
  -webkit-mask: url("../../assets/conversations_thread.svg") center center no-repeat;
  mask: url("../../assets/conversations_thread.svg") center center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.cometchat-conversations__subtitle-icon-none {
  display: none;
}

.cometchat-conversations__trailing-view {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-1,4px);
}

.cometchat-conversations__trailing-view-badge {
  display: flex;
  justify-content: flex-end;
  gap: var(--cometchat-padding-1,4px);
}

.cometchat-conversations__trailing-view-badge-count {
  display: flex;
  height: 20px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--cometchat-primary-color, #6852D6);
  overflow: hidden;
  color: var( --cometchat-static-white, #FFF);
  text-align: center;
  text-overflow: ellipsis;
  font:var(--cometchat-font-caption1-regular);
  font-style: normal;
}


.cometchat-conversations__subtitle {
  display: flex;
  gap: var(--cometchat-padding-1, 4px);
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cometchat-conversations .cometchat-list-item__body-subtitle {
  width: 100%;
}

.cometchat-conversations__list-item-active .cometchat-list-item{
  background-color:var(--cometchat-white-pressed,#E8E8E8)
}

.cometchat-conversations__list-item-online .cometchat-list-item__status {
  width: 14px;
  height: 14px;
  margin-right: -12px;
  position: relative;
  top: 20px;
  right: 15px;
  border-radius: var(--cometchat-radius-max, 1000px);
  background:  var(--cometchat-success-color,#09C26F);
  min-width: 14px;
  border: 2px solid var(--cometchat-neutral-color-50,#FFFFFF);
  display: flex;
  align-items: center;
  justify-content: center;
}


.cometchat-conversations__list-item-password .cometchat-list-item__status{
  width: 14px;
  height: 14px;
  margin-right: -14px;
  position: relative;
  top: 20px;
  right: 15px;
  border-radius: var(--cometchat-radius-max, 1000px);
  min-width: 14px;
  border: 2px solid var(--cometchat-neutral-color-50, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cometchat-success-color,#09C26F);
}

.cometchat-conversations__list-item-private .cometchat-list-item__status{
  width: 14px;
  height: 14px;
  margin-right: -14px;
  position: relative;
  top: 20px;
  right: 15px;
  border-radius: var(--cometchat-radius-max, 1000px);
  min-width: 14px;
  border: 2px solid var(--cometchat-neutral-color-50, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cometchat-warning-color,#FFAB00);
}

.cometchat-conversations__list-item-password .cometchat-list-item__status-icon {
  -webkit-mask: url("../../assets/lock.svg") center center no-repeat;
  mask: url("../../assets/lock.svg") center center no-repeat;
  height: 8px;
  width: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;  
  background:var(--cometchat-static-white,#FFF);
}

.cometchat-conversations__list-item-private .cometchat-list-item__status-icon {
  -webkit-mask: url("../../assets/shield.svg") center center no-repeat;
  mask: url("../../assets/shield.svg") center center no-repeat;
  height: 8px;
  width: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  background:var(--cometchat-static-white,#FFF);
}

.cometchat-conversations__trailing-view-options-delete-backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}


.cometchat-conversations__trailing-view-options .cometchat-menu-list__menu {
  background-color: transparent;
}

.cometchat-conversations__trailing-view-options .cometchat-menu-list__main-menu-item{
  background-color: transparent;
  box-shadow: none;
}


.cometchat-conversations__trailing-view-options .cometchat-menu-list__main-menu-item-icon-delete {
 -webkit-mask: url("../../assets/delete_icon.svg") center center no-repeat;
  mask: url("../../assets/delete_icon.svg") center center no-repeat;
  background: var(--cometchat-error-color,#F44649);
  width: 24px;
  height: 24px;
  -webkit-mask-size: contain;
}

.cometchat-conversations__trailing-view-options .cometchat-menu-list__sub-menu-icon {
width: 20px;
height: 20px;
background: var(--cometchat-icon-color-secondary, #A1A1A1);
}


.cometchat-conversations__trailing-view-options .cometchat-menu-list__sub-menu {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: var(--cometchat-radius-1, 4px);
  background: var( --cometchat-background-color-04, #E8E8E8);
}

.cometchat-conversations__subtitle-text .cometchat-mentions{
  border-radius: var(--cometchat-radius, 2px);
  background: rgba(104, 82, 214, 0.20);
  padding: 0px var(--cometchat-padding, 2px);

}
.cometchat-conversations__subtitle-text .cometchat-mentions-you{
  border-radius: var(--cometchat-radius, 2px);
  background: rgba(255, 171, 0, 0.20);
  padding: 0px var(--cometchat-padding, 2px);

}
.cometchat-conversations__subtitle-text .cometchat-mentions > span{ 
  color: var(--cometchat-text-color-highlight,#6852D6);
  font: var(--cometchat-font-body-regular);
}
.cometchat-conversations__subtitle-text .cometchat-mentions-you > span{ 
  color: var(--cometchat-warning-color,#FFAB00);
  font: var(--cometchat-font-body-regular);
}

.cometchat-conversations__subtitle-typing {
  overflow: hidden;
  color: var(--cometchat-text-color-highlight, #6852D6);
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-conversations .cometchat-confirm-dialog {
  width: 320px;
}

.cometchat-conversations__empty-state-view,
.cometchat-conversations__error-state-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: center;
  background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-conversations__empty-state-view-icon {
  -webkit-mask: url("../../assets/conversations_empty_state.svg") center center no-repeat;
  mask: url("../../assets/conversations_empty_state.svg") center center no-repeat;
}


.cometchat-conversations__error-state-view-icon {
  -webkit-mask: url("../../assets/list_error_state_icon.svg") center center no-repeat;
  mask: url("../../assets/list_error_state_icon.svg") center center no-repeat;
}

.cometchat-conversations__empty-state-view-body,
.cometchat-conversations__error-state-view-body {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding, 2px);
}

.cometchat-conversations__empty-state-view-body-title,
.cometchat-conversations__error-state-view-body-title {
  width: 280px;
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  font: var(--cometchat-font-heading3-bold);
  font-style: normal;
}

.cometchat-conversations__empty-state-view-body-description,
.cometchat-conversations__error-state-view-body-description {
  width: 280px;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-conversations__shimmer {
  width: 100%;
  height: 100%;
  background: var(--cometchat-background-color-01, #FFF);

}

.cometchat-conversations__shimmer-item {
  width: 100%;
  height: 72px;
  display: flex;
  width: 100%;
  min-width: 240px;
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  align-items: center;
  gap: 12px;
}

.cometchat-conversations__shimmer-item-avatar {
  display: flex;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: var(--cometchat-radius-max,1000px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
}

 .cometchat-conversations__shimmer-item-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-2, 8px);

}

.cometchat-conversations__shimmer-item-body-title-wrapper {
  display: flex;
  justify-content: space-between;
width: 100%;
}

.cometchat-conversations__shimmer-item-body-title {
  width: 50%;
  height: 22px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 1.5s infinite linear;

}

.cometchat-conversations__shimmer-item-body-subtitle {
  width:100%;
  height: 12px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 1.5s infinite linear;

}

.cometchat-conversations__shimmer-item-body-tail {
  width: 60px;
  height: 22px;
  min-height: 22px;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 10.5s infinite linear;
}
.cometchat-conversations .cometchat-menu-list__sub-menu-list-item .cometchat-menu-list__sub-menu-list-item-icon {
  display: none;
  }


@keyframes shimmerAnimation {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
