.cometchat-dropdown {
    position: relative;
}

.cometchat-dropdown__placeholder-text {
    display: flex;
    justify-content: space-between;
    height: 36px;
    width: 100%;
    padding: var(--cometchat-padding-2, 8px);
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: var(--cometchat-background-color-01, #FFF);
    border: 1px solid var(--cometchat-border-color-default, #F5F5F5);
    overflow: hidden;
    color: var(--cometchat-text-color-tertiary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
    line-height: 120%;
}

.cometchat-dropdown__placeholder-text label {
    color: var(--cometchat-text-color-primary, #141414);
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.cometchat-dropdown__placeholder-text::after {
    border: 1px solid var(--cometchat-border-color-highlight, #6852D6);
}

.cometchat-dropdown__arrow {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-primary, #6B7280);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask: url('../../assets/dropdown-arrow.svg');
    cursor: pointer;
}

.cometchat-dropdown__items {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    position: relative;
    z-index: 20;
}

.cometchat-dropdown__item {
    display: flex;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    align-self: stretch;
    background: var(--cometchat-background-color-01, #FFF);
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
    line-height: 120%;
}

.cometchat-dropdown__item:hover {
    border-right: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-white-hover, #FAFAFA);
    cursor: pointer;
}

.cometchat-dropdown__items-hidden {
    display: none;
}