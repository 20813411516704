
.cometchat-call-logs {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  flex-shrink: 0;
  background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-call-logs__outgoing-call {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

 .cometchat-call-logs__list-item-active .cometchat-list-item {
   background-color: var(--cometchat-white-pressed, #E8E8E8);
 }

.cometchat-call-logs__list-item-trailing-view {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;  
  background:var(--cometchat-icon-color-primary,#FFF)
}

.cometchat-call-logs__list-item-trailing-view-video {
  -webkit-mask: url("../../assets/call-log_video.svg") center center no-repeat;
  mask: url("../../assets/call-log_video.svg") center center no-repeat;
}

.cometchat-call-logs__list-item-trailing-view-audio {
  -webkit-mask: url("../../assets/call-log-audio.svg") center center no-repeat;
  mask: url("../../assets/call-log-audio.svg") center center no-repeat;
}

.cometchat-call-logs__list-item-subtitle {
  display: flex;
  gap: 4px;
}

.cometchat-call-logs__list-item-subtitle .cometchat-date{
  overflow: hidden;
  color:var(--cometchat-text-color-secondary,#727272);
  padding: 0px;
 text-overflow: ellipsis;
 font: var(--cometchat-font-body-regular);
}

.cometchat-call-logs__list-item-subtitle-icon {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;  
  background:var(--cometchat-icon-color-primary,#FFF);

}

.cometchat-call-logs__list-item-subtitle-icon-missed-call {
  -webkit-mask: url("../../assets/missedCallIcon.svg") center center no-repeat;
  mask: url("../../assets/missedCallIcon.svg") center center no-repeat;
  background-color: var(--cometchat-error-color,#F44649);
}


.cometchat-call-logs__list-item-subtitle-icon-outgoing-call {
  -webkit-mask: url("../../assets/call_made.svg") center center no-repeat;
  mask: url("../../assets/call_made.svg") center center no-repeat;
  background-color: var(--cometchat-success-color,#09C26F);

}

.cometchat-call-logs__list-item-subtitle-icon-incoming-call {
  -webkit-mask: url("../../assets/call_received.svg") center center no-repeat;
  mask: url("../../assets/call_received.svg") center center no-repeat;
  background-color: var(--cometchat-success-color,#09C26F);

}

.cometchat-call-logs__empty-state-view,
.cometchat-call-logs__error-state-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: center;
  background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-call-logs__empty-state-view-body,
.cometchat-call-logs__error-state-view-body {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding, 2px);
}

.cometchat-call-logs__empty-state-view-body-title,
.cometchat-call-logs__error-state-view-body-title {
  width: 280px;
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  font: var(--cometchat-font-heading3-bold);
  font-style: normal;
}

.cometchat-call-logs__empty-state-view-body-description,
.cometchat-call-logs__error-state-view-body-description {
  width: 280px;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-call-logs__shimmer {
  width: 100%;
  height: 100%;
  background: var(--cometchat-background-color-01, #FFF);

}

.cometchat-call-logs__shimmer-item {
  width: 100%;
  height: 72px;
  display: flex;
  width: 100%;
  min-width: 240px;
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  align-items: center;
  gap: 12px;
}

.cometchat-call-logs__shimmer-item-avatar {
  display: flex;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: var(--cometchat-radius-max,1000px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
}

.cometchat-call-logs__shimmer-item-body {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-call-logs__shimmer-item-body-title-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cometchat-call-logs__shimmer-item-body-title {
  width: 50%;
  height: 22px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 1.5s infinite linear;

}

.cometchat-call-logs__shimmer-item-body-subtitle {
  width:25%;
  height: 12px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 1.5s infinite linear;

}

.cometchat-call-logs__shimmer-item-body-tail {
  width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 10.5s infinite linear;
}

.cometchat-call-logs .cometchat-list-item {
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  cursor: pointer;
} 

.cometchat-call-logs .cometchat-list-item__body {
  padding: 0px;
}

@keyframes shimmerAnimation {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
