.cometchat-emoji-keyboard {
    display: flex;
    width: 300px;
    height: 300px;
    overflow: hidden;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    position: absolute;
    border-radius: var(--cometchat-radius-4, 16px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-emoji-keyboard__tabs {
    display: flex;
    width: 100%;
    min-height: 48px;
    overflow: scroll hidden;
    padding: var(--cometchat-padding-2, 8px);
    align-items: center;
    gap: var(--cometchat-padding-2, 4px);
    border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);
    cursor: pointer;
  /* Force hardware acceleration */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: scroll-position; /* Hint the browser for performance optimization */
}

.cometchat-emoji-keyboard__search {
    width: 100%;
     height: fit-content;
     padding: var(--cometchat-padding-3, 12px);
}

.cometchat-emoji-keyboard .cometchat-search-bar {
    height: 36px;
}

.cometchat-emoji-keyboard .cometchat-search-bar input,
.cometchat-emoji-keyboard .cometchat-search-bar input::placeholder {
    font: var(--cometchat-font-body-regular, 700 14px Roboto);
}

.cometchat-emoji-keyboard__list .cometchat-search {
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-3, 12px);
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--cometchat-radius-max, 1000px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-emoji-keyboard__list-content {
    display: contents;
}

.cometchat-emoji-keyboard__emoji-list {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: var(--cometchat-spacing-2, 8px);
}

.cometchat-emoji-keyboard__list-item {
    font-size: 24px;
    cursor: pointer;
    border-radius: var(--cometchat-radius-2, 8px);
    display: flex;
    justify-content: center;
    text-align: center;
    height: 32px;
    width: 32px;
}

.cometchat-emoji-keyboard__list-item:hover{
    background-color: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-emoji-keyboard__list-item:active{
    background-color: var(--cometchat-background-color-04, #E8E8E8);
}

.cometchat-emoji-keyboard__list-title {
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
    line-height: 120%;
    text-align: left;
}

.cometchat-emoji-keyboard__tab-active {
    background: var(--cometchat-extended-primary-color-100, #EDEAFA);
}

.cometchat-emoji-keyboard__tab {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12.8px;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-2, 8px);
}

.cometchat-emoji-keyboard__list::-webkit-scrollbar,
.cometchat-emoji-keyboard__list::-webkit-scrollbar-thumb,
.cometchat-emoji-keyboard__tabs::-webkit-scrollbar,
.cometchat-emoji-keyboard__tabs::-webkit-scrollbar-thumb {
    display: none;
}

.cometchat-emoji-keyboard__list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    padding: 0px var(--cometchat-padding-3, 12px) var(--cometchat-padding-3, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}

.cometchat-emoji-keyboard__tab-icon {
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    mask-size: contain;
    -webkit-mask-size: contain;
    width: 17px;
    height: 17px;
}

.cometchat-emoji-keyboard__tab-active .cometchat-emoji-keyboard__tab-icon {
    background: var(--cometchat-icon-color-highlight, #6852D6);
}


[data-theme="dark"] .cometchat-emoji-keyboard .cometchat-emoji-keyboard__list-item {
    color: var( --cometchat-neutral-color-500);
}