/* Base audio bubble container */
.cometchat-audio-bubble {
    border: none;
    display: flex;
    background: transparent;
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-2, 8px) 0px var(--cometchat-padding-2, 8px);
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--cometchat-radius-3, 12px);
    max-width: 240px;
    width: 240px;
}

/* Download icon for the tail view (right side of the audio bubble) */
.cometchat-audio-bubble__tail-view-download {
    mask: url("../../assets/download.svg");
    -webkit-mask: url("../../assets/download.svg");
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    cursor: pointer;
    background: var(--cometchat-static-white);

}

/* Waveform container inside the audio bubble body */
.cometchat-audio-bubble__body-wave {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

/* Container for download progress circle (24x24 size) */
.cometchat-audio-bubble__tail-view-download-progress {
    position: relative;
    width: 24px;
    height: 24px;
}

/* Rotate the SVG progress circle for correct start position */
.cometchat-audio-bubble__tail-view-download-progress svg {
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
}

/* Close/stop icon inside the progress view (positioned in the center) */
.cometchat-audio-bubble__tail-view-download-stop {
    mask: url("../../assets/close.svg");
    -webkit-mask: url("../../assets/close.svg");
    background: var(--cometchat-static-white);
    display: flex;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-mask-size: contain;
    mask-size: contain;
    cursor: pointer;
}
.cometchat-audio-bubble>div:not([class]) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--cometchat-padding-2, 8px);
    flex: 1 0 0;
}

/* Time display (current time / duration) inside the audio bubble body */
.cometchat-audio-bubble__body-time {
    color: var(--cometchat-static-white);
    font: var(--cometchat-font-caption2-regular);
    width: 100%;
    text-align: left;
}

/* Body of the audio bubble, containing waveform and time display */
.cometchat-audio-bubble__body {
    padding: 0px var(--cometchat-padding-2) 0px var(--cometchat-padding-3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: var(--cometchat-padding);
}

/* Play and pause icons for controlling the audio bubble */
.cometchat-audio-bubble__leading-view-play,
.cometchat-audio-bubble__leading-view-pause {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cometchat-primary-color);
    cursor: pointer;

}

/* Play button icon */
.cometchat-audio-bubble__leading-view-play {
    mask: url("../../assets/play_arrow.svg") center center no-repeat;
    -webkit-mask: url("../../assets/play_arrow.svg") center center no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
}

/* Pause button icon */
.cometchat-audio-bubble__leading-view-pause {
    mask: url("../../assets/pause.svg") center center no-repeat;
    -webkit-mask: url("../../assets/pause.svg") center center no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
}

/* Leading view container (play/pause button) with rounded background */
.cometchat-audio-bubble__leading-view {
    background: var(--cometchat-static-white);
    cursor: pointer;
    display: flex;
    width: 32px;
    border-radius: var(--cometchat-radius-max, 1000px);
    height: 32px;
    justify-content: center;
    align-items: center;
}

/* Background circle of the download progress indicator */
circle.cometchat-audio-bubble__tail-view-download-progress-background {
    stroke: var(--cometchat-static-white);
    stroke-dasharray: 113 113;
    opacity: .2;
    stroke-width: 2;
    fill: none;
}

/* Foreground circle that indicates download progress */
circle.cometchat-audio-bubble__tail-view-download-progress-foreground {
    stroke: var(--cometchat-static-white);
    stroke-dasharray: 0 113;
    stroke-width: 2;
    fill: none;
    stroke-linecap: round;
}

/* Outgoing audio bubble (message sent by the user) background */
.cometchat-audio-bubble-outgoing {
    background: var(--cometchat-primary-color);
}

/* Incoming audio bubble (message received by the user) background */
.cometchat-audio-bubble-incoming {
    background: var(--cometchat-neutral-color-300);
}

/* Tail view download button for incoming audio bubble */
.cometchat-audio-bubble-incoming .cometchat-audio-bubble__tail-view-download {
    background: var(--cometchat-primary-color);
}

/* Background circle for download progress in incoming bubble */
.cometchat-audio-bubble-incoming circle.cometchat-audio-bubble__tail-view-download-progress-background {
    stroke: var(--cometchat-primary-color);
    opacity: .2;
}

/* Foreground circle for download progress in incoming bubble */
.cometchat-audio-bubble-incoming circle.cometchat-audio-bubble__tail-view-download-progress-foreground {
    stroke: var(--cometchat-primary-color);
}

/* Stop download icon for incoming audio bubble */
.cometchat-audio-bubble-incoming .cometchat-audio-bubble__tail-view-download-stop {
    background: var(--cometchat-primary-color);
}

/* Time display in incoming audio bubble */
.cometchat-audio-bubble-incoming .cometchat-audio-bubble__body-time {
    color: var(--cometchat-neutral-color-600);
}