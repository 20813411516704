.cometchat-incoming-call {
    display: flex;
    width: 360px;
    padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-5, 20px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-4, 16px);
    border-radius: var(--cometchat-radius-3, 12px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-03, #F5F5F5);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    position: absolute;
    z-index: 10;
}

.cometchat-incoming-call .cometchat-list-item {
    width: 320px;
    padding: 0px;
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-incoming-call .cometchat-list-item__body {
    padding: 0px;
}

.cometchat-incoming-call .cometchat-list-item__body-title {
    color: var(--cometchat-text-color-primary, #FFF);
    font: var(--cometchat-font-heading3-bold, 700 20px Roboto);
}

.cometchat-incoming-call .cometchat-list-item__title-container {
    justify-content: space-around;
}

.cometchat-incoming-call .cometchat-list-item__trailing-view {
    width: fit-content;
    height: fit-content;
}

.cometchat-incoming-call__button-group {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.cometchat-incoming-call__button-decline {
    display: flex;
    height: 40px;
    width: 152px;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: var(--cometchat-error-color, #C73C3E);
    cursor: pointer;
}

.cometchat-incoming-call__button-decline .cometchat-button {
    width: 152px;
    background: var(--cometchat-error-color, #C73C3E);
}

.cometchat-incoming-call__button-accept {
    display: flex;
    height: 40px;
    width: 152px;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: var(--cometchat-success-color, #0B9F5D);
    cursor: pointer;
}

.cometchat-incoming-call__button-accept .cometchat-button {
    width: 152px;
    background: var(--cometchat-success-color, #0B9F5D);
}

.cometchat-incoming-call__avatar {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.cometchat-incoming-call__avatar .cometchat-avatar__text {
    color: var(--cometchat-primary-button-icon, #FFF);
    font: var(--cometchat-font-heading3-bold, 700 20px Roboto);
}

.cometchat-incoming-call__subtitle {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.cometchat-incoming-call__subtitle-icon {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
}

.cometchat-incoming-call__subtitle-text {
    color: var(--cometchat-text-color-secondary, #727272);
    font: var(--cometchat-font-heading4-regular, 400 16px Roboto);
}

.cometchat-incoming-call .cometchat-button:hover {
    background: transparent;
}

.cometchat-incoming-call .cometchat-button:active {
    background: transparent;
}