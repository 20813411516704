.cometchat-reaction-info {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: var(--cometchat-radius-2, 8px);
}

.cometchat-reaction-info__content {
    display: flex;
    padding: 8px var(--cometchat-padding-2, 8px);
    flex-direction: column;
    align-items: center;
    border-radius: var(--cometchat-radius-1, 4px);
    background: var(--cometchat-static-black, #141414);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    margin: -1px;
    min-height: 77px;
    min-width: 77px;
    justify-content: center;
}

.cometchat-reaction-info__tooltip {
    width: 32px;
    height: 6px;
    background: var(--cometchat-static-black, #141414);
    -webkit-mask: url("../../assets/tooltip.svg") center center no-repeat;
    -webkit-mask-size: contain;
}

.cometchat-reaction-info__tooltip-side {
    width: 6px;
    height: 32px;
    background: var(--cometchat-static-black, #141414);
    -webkit-mask: url("../../assets/tooltip-side.svg") center center no-repeat;
    -webkit-mask-size: contain;
}

.cometchat-reaction-info__emoji-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
}

.cometchat-reaction-info__emoji {
    color: var(--cometchat-static-white, #F9F8FD);
    text-align: center;
    font: var(--cometchat-font-heading1-regular, 400 24px Roboto);
}

.cometchat-reaction-info__title {
    color: var(--cometchat-static-white, #F9F8FD);
    text-align: center;
    font: var(--cometchat-font-caption1-regular, 400 12px Roboto);
}

.cometchat-reaction-info__description {
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    text-align: center;
    font: var(--cometchat-font-caption1-regular, 400 12px Roboto);
}

.cometchat-reaction-info__error {
    color: var(--cometchat-error-color, #F44649);
    text-align: center;
    font: var(--cometchat-font-caption1-regular, 400 12px Roboto);
}

.cometchat-reaction-info__loading {
    -webkit-mask: url("../../assets/Spinner-Gradient-1.svg") center center no-repeat;
    -webkit-mask-size: contain;
    height: 24px;
    width: 24px;
    border: 4px solid var(--cometchat-background-color-04, #E8E8E8);
    border-radius: 50%;
    border-top: 4px solid transparent;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}