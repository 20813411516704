.cometchat-tanslation-bubble {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--cometchat-radius-3, 12px);
  flex: 1 0 0;
  padding: var(--cometchat-padding-2) var(--cometchat-padding-2) 0px var(--cometchat-padding-2);
}

.cometchat-tanslation-bubble-incoming {
  align-items: flex-start;
  background: var(--cometchat-neutral-color-300, #E8E8E8);
}

.cometchat-tanslation-bubble-outgoing {
  align-items: flex-end;
  background: var(--cometchat-primary-color, #6852D6);
}

.cometchat-tanslation-bubble__original-text {
  width: 100%;
  max-width: 500px;
}
.cometchat-tanslation-bubble__original-text 
.cometchat-text-bubble {
  padding: 0px;
}

.cometchat-tanslation-bubble__translated-text {
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  text-align: left;
  max-width: 500px;
  margin: 0px;
  word-break: break-word;
  text-align: left;
  white-space: pre-line;
  overflow: auto;
}

.cometchat-tanslation-bubble-incoming .cometchat-tanslation-bubble__translated-text {
  width: 100%;
  display: flex;
  color: var(--cometchat-neutral-color-900, #141414);
}


.cometchat-tanslation-bubble-outgoing .cometchat-tanslation-bubble__translated-text {
  width: 100%;
  display: flex;
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-tanslation-bubble__separator {
  height: 1px;
  width: 100%;
}

.cometchat-tanslation-bubble-incoming .cometchat-tanslation-bubble__separator {
  background: var(--cometchat-neutral-color-400, #DCDCDC);
}


.cometchat-tanslation-bubble-outgoing .cometchat-tanslation-bubble__separator {
  background: var(--cometchat-extended-primary-color-800, #7965DB);
}



.cometchat-tanslation-bubble__helper-text {
  padding: var(--cometchat-padding-2) var(--cometchat-padding-2) var(--cometchat-padding-2) 0px;
  text-align: right;
  font: var(--cometchat-font-caption2-regular);
  font-style: normal;
}

.cometchat-tanslation-bubble-incoming .cometchat-tanslation-bubble__helper-text {
  color: var(--cometchat-neutral-color-600, #727272);
}


.cometchat-tanslation-bubble-outgoing .cometchat-tanslation-bubble__helper-text {
  color: var(--cometchat-static-white, #FFF);
}