/* Styles for the image bubble container */
.cometchat-image-bubble {
    padding: 0;
    background: transparent;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 350px;
    max-width: 250px;
    border-radius: var(--cometchat-radius-3);
    overflow: hidden;
    cursor: pointer;
}

/* Styles for the image inside the bubble */
.cometchat-image-bubble__body {
    margin: 0;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    object-fit: cover;

}

/* Specific styling for incoming image bubbles, giving it a background color
   for differentiation */
.cometchat-image-bubble-incoming {
    background: var(--cometchat-neutral-color-300);
}

/* Specific styling for outgoing image bubbles, using the primary color as
   the background to distinguish it from incoming bubbles */
.cometchat-image-bubble-outgoing {
    background: var(--cometchat-primary-color);
}