.cometchat-change-scope {
    display: flex;
    width: 400px;
    padding: var(--cometchat-padding-6, 24px) var(--cometchat-padding-6, 24px) var(--cometchat-padding-3, 12px) var(--cometchat-padding-6, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    border-radius: var(--cometchat-radius-5, 20px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-change-scope__icon-container {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-background-color-02, #FAFAFA);
}

.cometchat-change-scope__icon {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    mask: url("../../assets/change_scope.png") center center no-repeat;
    -webkit-mask: url("../../assets/change_scope.png") center center no-repeat;
    background: var(--cometchat-icon-color-highlight, #6852D6);
    mask-size: contain;
    -webkit-mask-size: contain;
}

.cometchat-change-scope__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}

.cometchat-change-scope__title {
    align-self: stretch;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading2-medium, 500 20px Roboto);
    line-height: 120%;
}

.cometchat-change-scope__description {
    align-self: stretch;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
    line-height: 120%;
}

.cometchat-change-scope__dropdown {
    width: 352px;
    height: 36px;
    gap: var(--cometchat-padding-2, 4px);
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-change-scope__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    height: fit-content;
    overflow: auto;
}

.cometchat-change-scope__list::-webkit-scrollbar,
.cometchat-change-scope__list::-webkit-scrollbar-thumb {
    display: none;
}

.cometchat-change-scope__list-item {
    display: flex;
    min-width: 240px;
    max-width: 1440px;
    height: 48px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    flex: 1 0 0;
    align-self: stretch;
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-change-scope__list-item:hover {
    background: var(--cometchat-white-hover, #FAFAFA);
    cursor: pointer;
}

.cometchat-change-scope__list-item-label {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading4-medium, 500 16px Roboto);
    flex: 1 0 0;
    text-align: left;
}

.cometchat-change-scope__button-container {
    display: flex;
    padding: var(--cometchat-padding-3, 12px) 0px;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}
.cometchat-change-scope__submit-button > .cometchat{
    height: 100%;
    width: 100%;
}
.cometchat-change-scope__submit-button{
    height: 40px;
    line-height: 120%;
    flex: 1;
}
.cometchat-change-scope__submit-button .cometchat-button{
    background: var(--cometchat-primary-button-background, #6852D6);
    color: var(--cometchat-primary-button-icon, #FFF);
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    align-items: center;
    align-self: stretch;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    font: var(--cometchat-font-body-medium, 500 14px Roboto);

}

.cometchat-change-scope__submit-button-disabled .cometchat-button{
    background: var(--cometchat-background-color-04, #E8E8E8);
    color: var(--cometchat-static-white, #FFF);
    pointer-events: none;
}

.cometchat-change-scope__cancel-button > .cometchat{
    height: 100%;
    width: 100%;
}
.cometchat-change-scope__cancel-button .cometchat-button{
    border: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
    background: var(--cometchat-background-color-01, #FFF);
    color: var(--cometchat-neutral-color-900, #141414);
    font: var(--cometchat-font-body-medium, 500 14px Roboto);
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    align-items: center;
    align-self: stretch;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: var(--cometchat-radius-2, 8px);
}
.cometchat-change-scope__button-container .cometchat-button .cometchat-button__text{
    font: inherit;
    color: inherit;
}
.cometchat-change-scope__cancel-button{
    height: 40px;
    line-height: 120%;
    flex: 1;
}
.cometchat-change-scope__error-view{
    color: var(--cometchat-error-color, #F44649);
    font: var(--cometchat-font-body-regular);
    font-style: normal;
    display: flex;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-2, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: rgba(244, 70, 73, 0.10);

}
