.cometchat-conversation-starter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 8px var(--cometchat-padding-2, 8px);
  align-self: stretch;
  flex-wrap: wrap;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
}

.cometchat-conversation-starter__item {
  display: flex;
  min-height: 33px;
  height: fit-content;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
  align-items: center;
  align-content: center;
  gap: 4px var(--cometchat-padding-1, 4px);
  flex-wrap: wrap;
  border-radius: var(--cometchat-radius-max, 1000px);
  border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  background: var(--cometchat-background-color-01, #FFF);
  cursor: pointer;
}

.cometchat-conversation-starter__item:hover {
  background: var(--cometchat-white-hover,#FAFAFA);
  cursor: pointer;
}

.cometchat-conversation-starter__item-button {
  background-color: transparent;
  border: none;
  color: var(--cometchat-text-color-primary);
  cursor: pointer;
}

.cometchat-conversation-starter__shimmer-container {
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: flex-start;
  gap: var(--cometchat-padding-2, 8px);
}


.cometchat-conversation-starter__shimmer-item {
  width: 70%;
  display: flex;
  height: 33px;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
  align-items: center;
  align-content: center;
  gap: 4px var(--cometchat-padding-1, 4px);
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: var(--cometchat-radius-max, 1000px);
  background: var(--cometchat-shimmer-gradient-color, linear-gradient(90deg, #E0E0E0 0%, #EEE 100%));
  background-size: 800px 104px;
  animation: shimmerAnimation 1.5s infinite linear;
}

.cometchat-conversation-starter__error-view {
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}