.cometchat-avatar {
    display: flex;
    width: 48px;
    height: 48px;
    background: var(--cometchat-extended-primary-color-500, #AA9EE8);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max, 1000px);
}

.cometchat-avatar__image {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--cometchat-primary-button-icon, #FFF);
    text-align: center;
    font: var(--cometchat-font-title-bold, 700 32px Roboto);
    border-radius: var(--cometchat-radius-max, 1000px);
}

.cometchat-avatar__text {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font: var(--cometchat-font-heading3-bold, 700 20px Roboto);
    color: var(--cometchat-primary-button-icon, #FFF);
    text-align: center;
}