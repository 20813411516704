.cometchat-action-sheet {
    display: flex;
    width: max-content;
    height: 310px;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    border-radius: var(--cometchat-radius-4, 16px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    overflow: hidden;
    overflow-y: auto;
    justify-content: flex-start;
}

.cometchat-action-sheet::-webkit-scrollbar,
.cometchat-action-sheet::-webkit-scrollbar-thumb {
    display: none;
}

.cometchat-action-sheet__item-icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-highlight, #6852D6);
    mask-size: contain;
    -webkit-mask-size: contain;
}

.cometchat-action-sheet__item-body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading4-regular, 400 16px Roboto);
    line-height: 120%;
    text-align: left;
}

.cometchat-action-sheet__item {
    display: flex;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    padding: var(--cometchat-padding-4, 16px);
    cursor: pointer;
    width: 100%;
}

.cometchat-action-sheet__item:hover  {
    background: var(--cometchat-white-hover);
}
.cometchat-popover .cometchat-action-sheet {
    height: inherit;
}