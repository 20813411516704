/* Main file bubble container with flex layout, gap, and background settings */
.cometchat-file-bubble {
    justify-content: space-between;
    overflow: hidden;
    box-sizing: border-box;
    gap: var(--cometchat-padding-2, 8px);
    border: none;
    border-radius: none;
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-2, 8px) var(--cometchat-padding-2, 8px) var(--cometchat-padding-1, 4px);
    flex-direction: row;
    align-items: center;
    border-radius: var(--cometchat-radius-3, 12px);
    align-self: stretch;
    width: 240px;
    max-width: 240px;
}
.cometchat-file-bubble>div:not([class]) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--cometchat-padding-2, 8px);
    flex: 1 0 0;
    width: inherit;
    overflow: hidden;
}
/* Body section of the file bubble with text alignment and overflow settings */
.cometchat-file-bubble__body {
    word-wrap: break-word;
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding);
}

/* Styling for the name inside the body */
.cometchat-file-bubble__body-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-medium);
    font-style: normal;
}

/* Styling for file details section (file size, type, etc.) */
.cometchat-file-bubble__body-details {
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--cometchat-font-caption2-regular);
    font-style: normal;

}

/* Leading view section (e.g., file icon) with flex settings */
.cometchat-file-bubble__leading-view {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: fit-content;
}

/* Download icon for the tail view (right side of the file bubble) */
.cometchat-file-bubble__tail-view-download {
    mask: url("../../assets/download.svg");
    -webkit-mask: url("../../assets/download.svg");
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    mask-size: contain;
    -webkit-mask-size: contain;
    background: var(--cometchat-primary-color);
    cursor: pointer;
}

/* Adjustments for incoming file bubble name text color */
.cometchat-file-bubble-incoming .cometchat-file-bubble__body-name {
    color: var(--cometchat-neutral-color-900);
}

/* Adjustments for incoming file bubble details text color */
.cometchat-file-bubble-incoming .cometchat-file-bubble__body-details {
    color: var(--cometchat-neutral-color-600);
}

/* Adjustments for outgoing file bubble name text color */
.cometchat-file-bubble-outgoing .cometchat-file-bubble__body-name {
    color: var(--cometchat-static-white);
}

/* Adjustments for outgoing file bubble details text color */
.cometchat-file-bubble-outgoing .cometchat-file-bubble__body-details {
    color: var(--cometchat-static-white);
}

/* Background color for incoming file bubbles */
.cometchat-file-bubble-incoming {
    background: var(--cometchat-neutral-color-300);
}

/* Background color for outgoing file bubbles */
.cometchat-file-bubble-outgoing {
    background: var(--cometchat-primary-color);
}


/* Progress circle container with a fixed width and height */
.cometchat-file-bubble__tail-view-download-progress {
    position: relative;
    width: 20px;
    height: 20px;
}

/* SVG within the download progress container with rotation */
.cometchat-file-bubble__tail-view-download-progress svg {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
}

/* Close icon in the progress view to stop/cancel download */
.cometchat-file-bubble__tail-view-download-stop {
    mask: url("../../assets/close.svg");
    -webkit-mask: url("../../assets/close.svg");
    background: var(--cometchat-primary-color);
    display: flex;
    width: 12px;
    height: 12px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-mask-size: contain;
    mask-size: contain;
    cursor: pointer;
}

/* Background circle for the download progress */
circle.cometchat-file-bubble__tail-view-download-progress-background {
    stroke: var(--cometchat-primary-color);
    stroke-dasharray: 113 113;
    stroke-width: 2;
    fill: none;
    opacity: .2;
}

/* Foreground circle that indicates download progress */
circle.cometchat-file-bubble__tail-view-download-progress-foreground {
    stroke: var(--cometchat-primary-color);
    stroke-dasharray: 0 113;
    stroke-width: 2;
    fill: none;
    stroke-linecap: round;
}

/* Tail view download button for outgoing file bubble */
.cometchat-file-bubble-outgoing .cometchat-file-bubble__tail-view-download {
    background: var(--cometchat-static-white);
}

/* Background circle for download progress in outgoing bubble */
.cometchat-file-bubble-outgoing circle.cometchat-file-bubble__tail-view-download-progress-background {
    stroke: var(--cometchat-static-white);
    opacity: .2;
}

/* Foreground circle for download progress in outgoing bubble */
.cometchat-file-bubble-outgoing circle.cometchat-file-bubble__tail-view-download-progress-foreground {
    stroke: var(--cometchat-static-white);
}

/* Stop download icon for outgoing file bubble */
.cometchat-file-bubble-outgoing .cometchat-file-bubble__tail-view-download-stop {
    background: var(--cometchat-static-white);
}