.cometchat-action-bubble {
    display: flex;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-3, 12px);
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    border: 1px solid var(--cometchat-border-color-default);
    background: var(--cometchat-background-color-02);
}

.cometchat-action-bubble__text {
    color: var(--cometchat-text-color-secondary);
    text-align: center;
    font: var(--cometchat-font-caption1-regular);
}

.cometchat-action-bubble__icon {
    display: none;
}