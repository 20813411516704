.cometchat-document-bubble {
    display: flex;
    width: 240px;
    flex-direction: column;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-1, 4px) 0px var(--cometchat-padding-1, 4px);
    gap: 0px;
    border-radius: var(--cometchat-radius-3, 12px);
    max-width: 240px;

}

.cometchat-document-bubble-incoming {
    background: var(--cometchat-neutral-color-300, #E8E8E8);
}

.cometchat-document-bubble-outgoing {
    background: var(--cometchat-primary-color, #6852D6);
}

.cometchat-document-bubble__body {
    display: flex;
    padding: var(--cometchat-padding-2, 8px) 0px var(--cometchat-padding-2, 8px) 0px;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-3, 12px);
}

.cometchat-document-bubble__banner-image {
    border-radius: var(--cometchat-radius-2, 8px);
}

.cometchat-document-bubble__banner-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: var(--cometchat-radius-2, 8px);
}

.cometchat-document-bubble__body-content {
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding, 2px);
}

.cometchat-document-bubble__body-content-name {
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-medium);
    font-style: normal;
    line-height: 120%;
    text-align: left;
}

.cometchat-document-bubble-incoming .cometchat-document-bubble__body-content-name {
    color: var(--cometchat-text-color-primary, #141414);
}

.cometchat-document-bubble-outgoing .cometchat-document-bubble__body-content-name {
    color: var(--cometchat-static-white, #FFF);
}

.cometchat-document-bubble__body-content-description {
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--cometchat-font-caption2-regular);
    font-style: normal;
    line-height: 120%;
}

.cometchat-document-bubble-incoming .cometchat-document-bubble__body-content-description {
    color: var(--cometchat-neutral-color-600, #727272);
}

.cometchat-document-bubble-outgoing .cometchat-document-bubble__body-content-description {
    color: var(--cometchat-static-white, #FFF);
}

.cometchat-document-bubble__body-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    mask-size: 100%;
}

.cometchat-collaborative-document .cometchat-document-bubble__body-icon {
    -webkit-mask: url("../../assets/collaborative_document_fill.svg")center center no-repeat;
    mask: url("../../assets/collaborative_document_fill.svg") center center no-repeat;
}

.cometchat-collaborative-whiteboard .cometchat-document-bubble__body-icon {
    -webkit-mask: url("../../assets/collaborative_whiteboard_fill.svg")center center no-repeat;
    mask: url("../../assets/collaborative_whiteboard_fill.svg") center center no-repeat;
}

.cometchat-document-bubble-incoming .cometchat-document-bubble__body-icon {
    background: var(--cometchat-primary-color, #6852D6);
}


.cometchat-document-bubble-outgoing .cometchat-document-bubble__body-icon {
    background: var(--cometchat-static-white, #FFF);
}

.cometchat-document-bubble__button {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-5, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    font: var(--cometchat-font-button-medium);
    font-style: normal;
    line-height: 120%;
    cursor: pointer;
}

.cometchat-document-bubble-incoming .cometchat-document-bubble__button {
    color: var(--cometchat-primary-button-background, #6852D6);
    border-top: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
}

.cometchat-document-bubble-outgoing .cometchat-document-bubble__button {
    border-top: 1px solid var(--cometchat-extended-primary-color-800, #7965DB);
    color: var(--cometchat-static-white, #FFF);
}