@import url('./components/index.css');

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

:root {
  /* Spacing Units
  Define consistent spacing units to be used throughout the application. These variables provide a range of spacing intervals for padding, margin, and layout styling, ensuring uniformity across components.
    */

  --cometchat-spacing: 2px;
  --cometchat-spacing-1: 4px;
  --cometchat-spacing-2: 8px;
  --cometchat-spacing-3: 12px;
  --cometchat-spacing-4: 16px;
  --cometchat-spacing-5: 20px;
  --cometchat-spacing-6: 24px;
  --cometchat-spacing-7: 28px;
  --cometchat-spacing-8: 32px;
  --cometchat-spacing-9: 36px;
  --cometchat-spacing-10: 40px;
  --cometchat-spacing-11: 44px;
  --cometchat-spacing-12: 48px;
  --cometchat-spacing-13: 52px;
  --cometchat-spacing-14: 56px;
  --cometchat-spacing-15: 60px;
  --cometchat-spacing-16: 64px;
  --cometchat-spacing-17: 68px;
  --cometchat-spacing-18: 72px;
  --cometchat-spacing-19: 76px;
  --cometchat-spacing-20: 80px;
  --cometchat-spacing-max: 1000px;

  /* Padding
  Map padding values to corresponding spacing units for consistent padding across all UI elements,
  enhancing visual rhythm and balance.
    */
  --cometchat-padding: var(--cometchat-spacing);
  --cometchat-padding-1: var(--cometchat-spacing-1);
  --cometchat-padding-2: var(--cometchat-spacing-2);
  --cometchat-padding-3: var(--cometchat-spacing-3);
  --cometchat-padding-4: var(--cometchat-spacing-4);
  --cometchat-padding-5: var(--cometchat-spacing-5);
  --cometchat-padding-6: var(--cometchat-spacing-6);
  --cometchat-padding-7: var(--cometchat-spacing-7);
  --cometchat-padding-8: var(--cometchat-spacing-8);
  --cometchat-padding-9: var(--cometchat-spacing-9);
  --cometchat-padding-10: var(--cometchat-spacing-10);

  /* Margin
    Map margin values to corresponding spacing units to ensure consistent spacing between elements and maintain a cohesive layout throughout the application.
    */
  --cometchat-margin: var(--cometchat-spacing);
  --cometchat-margin-1: var(--cometchat-spacing-1);
  --cometchat-margin-2: var(--cometchat-spacing-2);
  --cometchat-margin-3: var(--cometchat-spacing-3);
  --cometchat-margin-4: var(--cometchat-spacing-4);
  --cometchat-margin-5: var(--cometchat-spacing-5);
  --cometchat-margin-6: var(--cometchat-spacing-6);
  --cometchat-margin-7: var(--cometchat-spacing-7);
  --cometchat-margin-8: var(--cometchat-spacing-8);
  --cometchat-margin-9: var(--cometchat-spacing-9);
  --cometchat-margin-10: var(--cometchat-spacing-10);
  --cometchat-margin-11: var(--cometchat-spacing-11);
  --cometchat-margin-12: var(--cometchat-spacing-12);
  --cometchat-margin-13: var(--cometchat-spacing-13);
  --cometchat-margin-14: var(--cometchat-spacing-14);
  --cometchat-margin-15: var(--cometchat-spacing-15);
  --cometchat-margin-16: var(--cometchat-spacing-16);
  --cometchat-margin-17: var(--cometchat-spacing-17);
  --cometchat-margin-18: var(--cometchat-spacing-18);
  --cometchat-margin-19: var(--cometchat-spacing-19);
  --cometchat-margin-20: var(--cometchat-spacing-20);

  /* Border Radius
   Define border-radius values tied to spacing units,
      providing consistent rounded corners for UI elements,
      aligning with the overall design system.
     */
  --cometchat-radius: var(--cometchat-spacing);
  --cometchat-radius-1: var(--cometchat-spacing-1);
  --cometchat-radius-2: var(--cometchat-spacing-2);
  --cometchat-radius-3: var(--cometchat-spacing-3);
  --cometchat-radius-4: var(--cometchat-spacing-4);
  --cometchat-radius-5: var(--cometchat-spacing-5);
  --cometchat-radius-6: var(--cometchat-spacing-6);
  --cometchat-radius-max: var(--cometchat-spacing-max);
  /*
    These variables define the fonts for different text elements.
    Use these to maintain consistent typography styles throughout your application.
     */
  --cometchat-font-family: 'Roboto', 'Inter';
  --cometchat-font-title-bold: 700 32px/38.4px var(--cometchat-font-family);
  --cometchat-font-title-medium: 500 32px/38.4px var(--cometchat-font-family);
  --cometchat-font-title-regular: 400 32px/38.4px var(--cometchat-font-family);

  --cometchat-font-heading1-bold: 700 24px/28.8px var(--cometchat-font-family);
  --cometchat-font-heading1-medium: 500 24px/28.8px var(--cometchat-font-family);
  --cometchat-font-heading1-regular: 400 24px/28.8px var(--cometchat-font-family);

  --cometchat-font-heading2-bold: 700 20px/24px var(--cometchat-font-family);
  --cometchat-font-heading2-medium: 500 20px/24px var(--cometchat-font-family);
  --cometchat-font-heading2-regular: 400 20px/24px var(--cometchat-font-family);

  --cometchat-font-heading3-bold: 700 18px/21.6px var(--cometchat-font-family);
  --cometchat-font-heading3-medium: 500 18px/21.6px var(--cometchat-font-family);
  --cometchat-font-heading3-regular: 400 18px/21.6px var(--cometchat-font-family);

  --cometchat-font-heading4-bold: 700 16px/19.2px var(--cometchat-font-family);
  --cometchat-font-heading4-medium: 500 16px/19.2px var(--cometchat-font-family);
  --cometchat-font-heading4-regular: 400 16px/19.2px var(--cometchat-font-family);

  --cometchat-font-body-bold: 700 14px/16.8px var(--cometchat-font-family);
  --cometchat-font-body-medium: 500 14px/16.8px var(--cometchat-font-family);
  --cometchat-font-body-regular: 400 14px/16.8px var(--cometchat-font-family);

  --cometchat-font-caption1-bold: 700 12px/14.4px var(--cometchat-font-family);
  --cometchat-font-caption1-medium: 500 12px/14.4px var(--cometchat-font-family);
  --cometchat-font-caption1-regular: 400 12px/14.4px var(--cometchat-font-family);

  --cometchat-font-caption2-bold: 700 10px/12px var(--cometchat-font-family);
  --cometchat-font-caption2-medium: 500 10px/12px var(--cometchat-font-family);
  --cometchat-font-caption2-regular: 400 10px/12px var(--cometchat-font-family);

  --cometchat-font-button-bold: 700 14px/16.8px var(--cometchat-font-family);
  --cometchat-font-button-medium: 500 14px/16.8px var(--cometchat-font-family);
  --cometchat-font-button-regular: 400 14px/16.8px var(--cometchat-font-family);

  --cometchat-font-link: 400 14px/19.6px var(--cometchat-font-family);

  /*Primary & Extended Colors
   Set the primary color and a range of extended shades to be used throughout the application,
    maintaining a consistent visual theme with variations to suit different UI components and states.
     */
  --cometchat-primary-color: #6852D6;
  --cometchat-extended-primary-color-50: #F9F8FD;
  --cometchat-extended-primary-color-100: #EDEAFA;
  --cometchat-extended-primary-color-200: #DCD7F6;
  --cometchat-extended-primary-color-300: #CCC4F1;
  --cometchat-extended-primary-color-400: #BBB1ED;
  --cometchat-extended-primary-color-500: #AA9EE8;
  --cometchat-extended-primary-color-600: #9A8BE4;
  --cometchat-extended-primary-color-700: #8978DF;
  --cometchat-extended-primary-color-800: #7965DB;
  --cometchat-extended-primary-color-900: #5D49BE;

  /*
    Neutral Colors
     Define a neutral color palette, providing versatile shades for backgrounds,text and borders. These neutrals serve as the foundation for the application's color scheme.
     */
  --cometchat-neutral-color-50: #FFFFFF;
  --cometchat-neutral-color-100: #FAFAFA;
  --cometchat-neutral-color-200: #F5F5F5;
  --cometchat-neutral-color-300: #E8E8E8;
  --cometchat-neutral-color-400: #DCDCDC;
  --cometchat-neutral-color-500: #A1A1A1;
  --cometchat-neutral-color-600: #727272;
  --cometchat-neutral-color-700: #5B5B5B;
  --cometchat-neutral-color-800: #434343;
  --cometchat-neutral-color-900: #141414;

  /*
  Alert Colors
   Assign colors for various alerts, such as informational messages, warnings,  success notifications,
and errors.
     */
  --cometchat-info-color: #0B7BEA;
  --cometchat-warning-color: #FFAB00;
  --cometchat-success-color: #09C26F;
  --cometchat-error-color: #F44649;

  /*
    Static colors
     Assign colors for various alerts, such as informational messages, warnings,  success notifications,
  and errors.
       */

  --cometchat-static-black: #141414;
  --cometchat-static-white: #FFFFFF;

  /*
  Background Colors
   Specify background colors derived from the neutral palette for different sections of the application.
     */
  --cometchat-background-color-01: var(--cometchat-neutral-color-50);
  --cometchat-background-color-02: var(--cometchat-neutral-color-100);
  --cometchat-background-color-03: var(--cometchat-neutral-color-200);
  --cometchat-background-color-04: var(--cometchat-neutral-color-300);



  /*
  Border Colors
Define border colors for UI elements,
    creating clear visual boundaries
      */
  --cometchat-border-color-light: var(--cometchat-neutral-color-200);
  --cometchat-border-color-default: var(--cometchat-neutral-color-300);
  --cometchat-border-color-dark: var(--cometchat-neutral-color-400);
  --cometchat-border-color-highlight: var(--cometchat-primary-color);


  /*
  Text Colors
  Define text colors to ensure readability and contrast against various background shades. These include primary, secondary, tertiary, disabled, and highlight text colors.
      */
  --cometchat-text-color-primary: var(--cometchat-neutral-color-900);
  --cometchat-text-color-secondary: var(--cometchat-neutral-color-600);
  --cometchat-text-color-tertiary: var(--cometchat-neutral-color-500);
  --cometchat-text-color-disabled: var(--cometchat-neutral-color-400);
  --cometchat-text-color-white: var(--cometchat-neutral-color-50);
  --cometchat-text-color-highlight: var(--cometchat-primary-color);

  /*
  Icon Colors
   Assign colors for icons throughout the application. Icon colors align with the text and background palettes.
     */
  --cometchat-icon-color-primary: var(--cometchat-neutral-color-900);
  --cometchat-icon-color-secondary: var(--cometchat-neutral-color-500);
  --cometchat-icon-color-tertiary: var(--cometchat-neutral-color-400);
  --cometchat-icon-color-white: var(--cometchat-neutral-color-50);
  --cometchat-icon-color-highlight: var(--cometchat-primary-color);

  /*
    Styling for shimmer effects shown while loading content in the uikit.
     */
  --cometchat-shimmer-background-color: var(--cometchat-neutral-color-200);
  --cometchat-shimmer-gradient-color: linear-gradient(90deg, #E0E0E0 0%, #EEE 100%);

  /*
    Styling for buttons.
     */
  --cometchat-primary-button-background: var(--cometchat-primary-color);
  --cometchat-primary-button-text: var(--cometchat-static-white);
  --cometchat-primary-button-icon: var(--cometchat-static-white);
  --cometchat-secondary-button-background: var(--cometchat-neutral-color-900);
  --cometchat-secondary-button-icon: var(--cometchat-neutral-color-900);
  --cometchat-secondary-button-text: var(--cometchat-neutral-color-900);
  --cometchat-link-button: var(--cometchat-info-color);
  --cometchat-fab-button-background: var(--cometchat-primary-color);
  --cometchat-fab-button-icon: var(--cometchat-static-white);
  --cometchat-white-hover: var(--cometchat-neutral-color-100);
  --cometchat-white-pressed: var(--cometchat-neutral-color-300);
}

[data-theme="dark"] {
  --cometchat-primary-color: #6852D6;
  --cometchat-extended-primary-color-50: #15102B;
  --cometchat-extended-primary-color-100: #1D173C;
  --cometchat-extended-primary-color-200: #251E4D;
  --cometchat-extended-primary-color-300: #2E245E;
  --cometchat-extended-primary-color-400: #362B6F;
  --cometchat-extended-primary-color-500: #3E3180;
  --cometchat-extended-primary-color-600: #473892;
  --cometchat-extended-primary-color-700: #4F3EA3;
  --cometchat-extended-primary-color-800: #5745B4;
  --cometchat-extended-primary-color-900: #7460D9;
  /**
   * Neutral Colors
  * Define a neutral color palette, providing versatile shades for backgrounds,text and borders. These neutrals serve as the foundation for the application's color scheme.
   **/
  --cometchat-neutral-color-50: #141414;
  --cometchat-neutral-color-100: #1A1A1A;
  --cometchat-neutral-color-200: #272727;
  --cometchat-neutral-color-300: #383838;
  --cometchat-neutral-color-400: #4C4C4C;
  --cometchat-neutral-color-500: #858585;
  --cometchat-neutral-color-600: #989898;
  --cometchat-neutral-color-700: #A8A8A8;
  --cometchat-neutral-color-800: #C8C8C8;
  --cometchat-neutral-color-900: #FFFFFF;
  /*
  Alert Colors
  Assign colors for various alerts, such as informational messages, warnings,  success notifications,
  and errors.
     */

  --cometchat-info-color: #0D66BF;
  --cometchat-warning-color: #D08D04;
  --cometchat-success-color: #0B9F5D;
  --cometchat-message-seen-color: #56E8A7;
  --cometchat-error-color: #C73C3E;
  
    /*
    Static colors
     Assign colors for various alerts, such as informational messages, warnings,  success notifications,
  and errors.
       */

       --cometchat-static-black: #141414;
       --cometchat-static-white: #FFFFFF;

  /*
  Background Colors
   Specify background colors derived from the neutral palette for different sections of the application.
     */
  --cometchat-background-color-01: var(--cometchat-neutral-color-50);
  --cometchat-background-color-02: var(--cometchat-neutral-color-100);
  --cometchat-background-color-03: var(--cometchat-neutral-color-200);
  --cometchat-background-color-04: var(--cometchat-neutral-color-300);

  /*
  Border Colors
Define border colors for UI elements,
    creating clear visual boundaries
      */
  --cometchat-border-color-light: var(--cometchat-neutral-color-200);
  --cometchat-border-color-default: var(--cometchat-neutral-color-300);
  --cometchat-border-color-dark: var(--cometchat-neutral-color-400);
  --cometchat-border-color-highlight: var(--cometchat-primary-color);

  /*
  Text Colors
  Define text colors to ensure readability and contrast against various background shades. These include primary, secondary, tertiary, disabled, and highlight text colors.
      */
  --cometchat-text-color-primary: var(--cometchat-neutral-color-900);
  --cometchat-text-color-secondary: var(--cometchat-neutral-color-600);
  --cometchat-text-color-tertiary: var(--cometchat-neutral-color-500);
  --cometchat-text-color-disabled: var(--cometchat-neutral-color-400);
  --cometchat-text-color-white: var(--cometchat-neutral-color-50);
  --cometchat-text-color-highlight: var(--cometchat-primary-color);

  /*
  Icon Colors
   Assign colors for icons throughout the application. Icon colors align with the text and background palettes.
     */
  --cometchat-icon-color-primary: var(--cometchat-neutral-color-900);
  --cometchat-icon-color-secondary: var(--cometchat-neutral-color-500);
  --cometchat-icon-color-tertiary: var(--cometchat-neutral-color-400);
  --cometchat-icon-color-white: var(--cometchat-neutral-color-50);
  --cometchat-icon-color-highlight: var(--cometchat-primary-color);
  /*
  Styling for shimmer effects shown while loading content in the uikit.
   */
  --cometchat-shimmer-background-color: var(--cometchat-background-color-01);
  --cometchat-shimmer-gradient-color: linear-gradient(90deg, #383838 0%, #272727 100%);

  /*
   Styling for buttons.
  */
  --cometchat-primary-button-background: var(--cometchat-primary-color);
  --cometchat-primary-button-text: var(--cometchat-static-white);
  --cometchat-primary-button-icon: var(--cometchat-static-white);
  --cometchat-secondary-button-background: var(--cometchat-neutral-color-900);
  --cometchat-secondary-button-text: var(--cometchat-neutral-color-900);
  --cometchat-secondary-button-icon: var(--cometchat-neutral-color-900);
  --cometchat-link-button: var(--cometchat-info-color);
  --cometchat-fab-button-background: var(--cometchat-primary-color);
  --cometchat-fab-button-icon: var(--cometchat-static-white);
  --cometchat-white-hover: var(--cometchat-neutral-color-100);
  --cometchat-white-pressed: var(--cometchat-neutral-color-300);
}