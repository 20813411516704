.cometchat-toast{
    display: inline-block;
    padding: var(--cometchat-padding-2,8px);
    border-radius: var(--cometchat-radius-1,4ppx);
    background: var(--cometchat-static-black);
    position: fixed;
    color: var(--cometchat-static-white, #F9F8FD);
    font: var(--cometchat-font-caption1-regular);
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    position: absolute;
}