.cometchat-menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-2, 8px);
    background: transparent;
}

.cometchat-menu-list__main-menu {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.cometchat-menu-list__sub-menu-list {
    display: flex;
    position: fixed;
    overflow: hidden;
    z-index: 3;
    width: 160px;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-menu-list__main-menu-item {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
}

.cometchat-menu-list__sub-menu-list-item {
    display: flex;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    padding: var(--cometchat-padding-3, 12px);
    flex: 1 0 0;
    cursor: pointer;
    background: var(--cometchat-background-color-01, #FFF);
}
.cometchat-menu-list__sub-menu-list-item:hover{
    background: var(--cometchat-white-hover);
}
.cometchat-menu-list__menu {
    display: flex;
    height: 48px;
    width: 100%;
    align-items: center;
    gap: var(--cometchat-padding-2, 12px);
    align-self: stretch;
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-menu-list__main-menu-item-icon {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
}

.cometchat-menu-list__sub-menu-list-item-icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
}

.cometchat-menu-list__sub-menu-item-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    text-align: left;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
    line-height: 120%;
    
}

.cometchat-menu-list__sub-menu {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.cometchat-menu-list__sub-menu-icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask: url('../../assets/more_icon.svg');
    -webkit-mask-size: contain;
    cursor: pointer;
}

.cometchat-menu-list__menu-wrapper {
    display: flex;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}