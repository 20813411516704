.cometchat-fullscreen-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #141414CC;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.cometchat-fullscreen-viewer__header {
  width:fit-content;
  position: absolute;
  left: 10px;
  top: 10px;
}

.cometchat-fullscreen-viewer .cometchat-fullscreen-viewer__header-item .cometchat-list-item {
  background-color: transparent;
}

.cometchat-fullscreen-viewer .cometchat-fullscreen-viewer__header-item .cometchat-avatar img {
  width: 100%;
  height: 100%;
}

.cometchat-fullscreen-viewer .cometchat-fullscreen-viewer__header-item .cometchat-list-item__body-title {
  overflow: hidden;
  color: var(--cometchat-static-white, #FFF);
  text-overflow: ellipsis;
  font: var(--cometchat-font-heading4-medium);
  font-style: normal;
}

.cometchat-fullscreen-viewer .cometchat-fullscreen-viewer__header-item .cometchat-list-item__body-subtitle  {
  overflow: hidden;
  color: var(--cometchat-text-color-tertiary, #A1A1A1);
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--cometchat-font-caption1-regular);
}

.cometchat-fullscreen-viewer__body-image {
  max-width: 400px;
  max-height: 600px;
  flex-shrink: 0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-fullscreen-viewer__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  mask-repeat: no-repeat;
  background-color: var(--cometchat-primary-button-icon, #FFFFFF);
  height: 32px;
  width: 32px;
  -webkit-mask: url("../../assets/close.svg");
  mask: url("../../assets/close.svg");
  -webkit-mask-size: 100%;
  mask-size: 100%;
}


.cometchat-fullscreen-viewer__body-download-progress {
  position: relative;
  width: 100px;
  height: 100px;
}

.cometchat-fullscreen-viewer__body-download-progress svg {
  width: 100px;
  height: 100px;
  transform: rotate(-90deg);
}

circle.cometchat-fullscreen-viewer__body-download-progress-background {
  stroke: var(--cometchat-static-white);
  stroke-dasharray: 113 113;
  stroke-width: 2;
  fill: none;
  opacity: .2;
}

circle.cometchat-fullscreen-viewer__body-download-progress-foreground {
  stroke: var(--cometchat-static-white);
  stroke-dasharray: 0 113;
  stroke-width: 2;
  fill: none;
  stroke-linecap: round;
}
