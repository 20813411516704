/* Container for the confirmation dialog */
.cometchat-confirm-dialog {
    margin: 0 auto;
    width: 400px;
    box-sizing: border-box;
    display: flex;
    width: 400px;
    padding: var(--cometchat-padding-6, 24px) var(--cometchat-padding-6, 24px) var(--cometchat-padding-3, 12px) var(--cometchat-padding-6, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    border-radius: var(--cometchat-radius-4, 16px);
    border: 1px solid var(--cometchat-border-color-light);
    background: var(--cometchat-background-color-01);
    /* Shadow-LG */
    flex-wrap: wrap;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

/* Title inside the confirmation dialog */
.cometchat-confirm-dialog__content-title {
    color: var(--cometchat-text-color-primary);
    text-align: center;
    font: var(--cometchat-font-heading2-medium);
    font-style: normal;
}

/* Content inside the dialog, typically descriptions and details */
.cometchat-confirm-dialog__content {
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding);
}

/* Description inside the dialog */
.cometchat-confirm-dialog__content-description {
    width: 100%;
    color: var(--cometchat-text-color-secondary);
    text-align: center;
    font: var(--cometchat-font-body-regular);
    font-style: normal;

}

/* Button group for the dialog */
.cometchat-confirm-dialog__button-group {
    display: flex;
    padding: var(--cometchat-padding-3, 12px) 0px;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    width: 100%;
    overflow: hidden;
}

/* Icon wrapper for confirmation dialog */
.cometchat-confirm-dialog__icon-wrapper {
    display: flex;
    width: 80px;
    height: 80px;
    padding: var(--cometchat-padding-4);
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-background-color-02);
    flex-shrink: 1;

}

/* Styling for the confirmation icon */
.cometchat-confirm-dialog__icon-wrapper-icon {
    mask: url("../../assets/delete.svg") center center no-repeat;
    -webkit-mask: url("../../assets/delete.svg") center center no-repeat;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cometchat-error-color);
    -webkit-mask-size: contain;
    mask-size: contain;
}

/* Submit button inside the confirmation dialog */
.cometchat-confirm-dialog__button-group-submit .cometchat-button {
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    background: var(--cometchat-error-color);
    border: 1px solid var(--cometchat-border-color-light);
}
.cometchat-confirm-dialog__button-group .cometchat-button{
    height: 100%;
    width: 100%;
}
/* Styling for buttons inside the dialog */
 .cometchat-confirm-dialog__button-group .cometchat-confirm-dialog__button-group-submit,
 .cometchat-confirm-dialog__button-group .cometchat-confirm-dialog__button-group-cancel{
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    font: var(--cometchat-font-button-medium);
    font-style: normal;
    cursor: pointer;
}
.cometchat-confirm-dialog__button-group-submit> .cometchat,
.cometchat-confirm-dialog__button-group-cancel> .cometchat{
    height: 100%;
    width: 100%;
}
/* Cancel button inside the confirmation dialog */
.cometchat-confirm-dialog__button-group-cancel  .cometchat-button {
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    border: 1px solid var(--cometchat-border-color-dark);
    background: var(--cometchat-background-color-01, #FFF);
}
.cometchat-confirm-dialog__button-group-cancel  .cometchat-button .cometchat-button__text{
    color: var(--cometchat-text-color-primary);

}
.cometchat-confirm-dialog__button-group-submit  .cometchat-button .cometchat-button__text{
    color: var(--cometchat-static-white);

}
.cometchat-confirm-dialog-error-view{
    border-radius: var(--cometchat-radius-4, 12px) var(--cometchat-radius-4, 12px) 0px 0px;
   background: linear-gradient(0deg, rgba(244, 70, 73, 0.10) 0%, rgba(244, 70, 73, 0.10) 100%), var(--cometchat-background-color-01, #FFF);
   overflow: hidden;
color: var(--cometchat-error-color, #F44649);
text-overflow: ellipsis;
font:var(--cometchat-font-body-regular);
font-style: normal;
display: flex;
width: 100%;
padding: var(--cometchat-padding-2, 8px);
align-items: flex-start;
height: 48px;
margin-bottom: -16px;
justify-content: center;
}