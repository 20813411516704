.cometchat-quick-view {
    position: relative;
    min-width: 190px;
    padding: 5px;
    box-sizing: border-box;
    background: #efefef;
    height: fit-content;
    width: 100%;
    border-radius: 8px;
}

.cometchat-quick-view__content {
    margin-left: 14px;
}

.cometchat-quick-view__container {
    display: flex;
    align-items: center;
}

.cometchat-quick-view-button {
    position: absolute;
    top: 2px;
    right: 4px;
    height: 16px;
    width: 16px;
    border: none;
    border-radius: 0;
    background: transparent;
}

.cometchat-quick-view-bar {
    position: absolute;
    height: 90%;
    top: 5%;
    left: 5px;
    border-radius: 5px;
    background: "black";
    width: "4px";
}

.cometchat-quick-view-title {
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0px;
    font: "600 13px Inter, sans-serif";
    color: "grey";
}

.cometchat-quick-view-subtitle {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0px;
    text-align: left;
    font: "400 13px Inter, sans-serif";
    color: "grey";
}