.cometchat-reaction-list {
    display: flex;
    width: 280px;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    border-radius: var(--cometchat-radius-5, 20px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-reaction-list__error {
    display: flex;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.cometchat-reaction-list__tabs {
    display: flex;
    padding-top: var(--cometchat-padding-2, 8px);
    max-width: 280px;
    align-items: center;
    align-self: stretch;
    overflow-x: auto;
    border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-reaction-list__list::-webkit-scrollbar,
.cometchat-reaction-list__list::-webkit-scrollbar-thumb,
.cometchat-reaction-list__tabs::-webkit-scrollbar,
.cometchat-reaction-list__tabs::-webkit-scrollbar-thumb {
    display: none;
}

.cometchat-reaction-list__tabs-tab {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
}

.cometchat-reaction-list__tabs-tab-active {
    border-bottom: 2px solid var(--cometchat-primary-color, #6852D6);
}

.cometchat-reaction-list__tabs-tab-emoji,
.cometchat-reaction-list__tabs-tab-count {
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-body-medium, 500 14px Roboto);
}

.cometchat-reaction-list__tabs-tab-count-active,
.cometchat-reaction-list__tabs-tab-emoji-active {
    color: var(--cometchat-text-color-highlight, #6852D6);
}

.cometchat-reaction-list__list {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--cometchat-radius-0, 0px);
    background: var(--cometchat-background-color-01, #FFF);
    overflow-y: auto;
    height: 200px;
}

.cometchat-reaction-list .cometchat-list-item {
    width: 280px;
    cursor: pointer;
}

.cometchat-reaction-list .cometchat-list-item__leading-view,
.cometchat-reaction-list .cometchat-list-item__body {
    padding: 0px;
    flex-shrink: inherit;
}

.cometchat-reaction-list .cometchat-list-item__body-title {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-medium, 500 14px Roboto);
}

.cometchat-reaction-list .cometchat-list-item__body-subtitle {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-caption1-regular, 400 12px Roboto);
}

.cometchat-reaction-list .cometchat-avatar,
.cometchat-reaction-list .cometchat-avatar__image,
.cometchat-reaction-list .cometchat-list-item__leading-view {
    height: 32px;
    width: 32px;
}

.cometchat-reaction-list .cometchat-list-item__title-container {
    gap: 0px;
}

.cometchat-reaction-list__shimmer {
    display: flex;
    width: 280px;
    max-height: 300px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--cometchat-radius-4, 16px);
    border: 1px solid var(--cometchat-background-color-01, #FFF);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-reaction-list__shimmer-tabs {
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px) var(--cometchat-padding-4, 16px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-reaction-list__shimmer-tab {
    background: var(--cometchat-shimmer-gradient-color);
    display: flex;
    width: 48px;
    height: 16px;
    border-radius: var(--cometchat-radius-2, 8px);
    justify-content: center;
    align-items: center;
    animation: shimmerAnimation 10s infinite linear;
}

.cometchat-reaction-list__shimmer-item {
    display: flex;
    width: 280px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    flex: 1 0 0;
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-reaction-list__shimmer-item-icon {
    background: var(--cometchat-shimmer-gradient-color);
    display: flex;
    border-radius: var(--cometchat-radius-max, 1000px);
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    animation: shimmerAnimation 10s infinite linear;
}

.cometchat-reaction-list__shimmer-item-content {
    background: var(--cometchat-shimmer-gradient-color);
    border-radius: var(--cometchat-radius-2, 8px);
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    animation: shimmerAnimation 5s infinite linear;
}

.cometchat-reaction-list__shimmer-item-tailview {
    background: var(--cometchat-shimmer-gradient-color);
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: var(--cometchat-radius-max, 1000px);
    justify-content: center;
    align-items: center;
    animation: shimmerAnimation 10s infinite linear;
}

@keyframes shimmerAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}