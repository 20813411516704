.cometchat-button {
    display: flex;
    height: 40px;
    width: 160px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    border: none;
    background: var(--cometchat-primary-button-background, #6852D6);
    cursor: pointer;
}

.cometchat-button__text {
    color: var(--cometchat-static-white, #FFFFFF);
    font: var(--cometchat-font-button-medium, 500 14px Roboto);
    line-height: 120%;
    cursor: pointer;
}

.cometchat-button__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cometchat-button__icon-default {
    height: 24px;
    width: 24px;
}

.cometchat-button:hover {
    background: var(--cometchat-extended-primary-color-800, #7A6BD6);
}

.cometchat-button:active {
    background: var(--cometchat-extended-primary-color-900, #5D49BE);
    outline: none;
}

.cometchat-button:focus {
    outline: none;
}

.cometchat-button__loading-view {
    width: 24px;
    height: 24px;
    animation: spin .8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}