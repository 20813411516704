 :host {
     width: 100%;
     text-align: center;
 }

 .cc-single_select-option {
     padding: 10px;
     box-sizing: border-box;
     cursor: pointer;
     min-height: 20px;
     min-width: 80px;
     display: flex;
     justify-content: center;
     align-items: center;
     font: 400 14px Inter;
     color: grey;
     background: white;
     border: 1px solid #dedede;
     border-radius: 2px;

     :hover {
         font: 400 14px Inter;
         color: black;
         background: #efefef;
     }
 }

 .cc-single_select-wrapper {
     height: 100%;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     border: none;
     border-radius: 6px;
 }
