/* Container for the outgoing call UI, responsible for layout and overall styling */
.cometchat-outgoing-call {
    display: flex;
    width: 360px;
    height: 540px;
    padding: var(--cometchat-padding-5, 20px) 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-5, 20px);
    border: 1px solid var(--cometchat-border-color-light);
    background: var(--cometchat-background-color-01);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

/* Title for the outgoing call UI */
.cometchat-outgoing-call__title {
    color: var(--cometchat-text-color-primary);
    text-align: center;
    font: var(--cometchat-font-title-medium);
}

.cometchat-outgoing-call__title-container {
    display: flex;
    flex-direction: column;
}

/* Subtitle text for the outgoing call UI */
.cometchat-outgoing-call__subtitle {
    color: var(--cometchat-text-color-secondary);
    text-align: center;
    font: var(--cometchat-font-heading4-regular);
}

/* Avatar container for the outgoing call, controlling its size and layout */
.cometchat-outgoing-call__avatar {
    display: flex;
    width: 160px;
    height: 160px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max);
}

/* Avatar image inside the avatar container */
.cometchat-outgoing-call__avatar .cometchat-avatar {
    width: 160px;
    height: 160px;
}

/* Avatar text inside the avatar container */
.cometchat-outgoing-call__avatar .cometchat-avatar__text {
    font: 700 64px/76.8px var(--cometchat-font-family);
    height: fit-content;
    width: fit-content;
    font-style: normal;
}

/* Button container for actions during the outgoing call */
.cometchat-outgoing-call__button .cometchat-button {
    display: flex;
    padding: var(--cometchat-padding-4);
    align-items: center;
    gap: var(--cometchat-padding-2);
    border-radius: var(--cometchat-radius-max);
    background: var(--cometchat-error-color);
    justify-content: center;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
}

/* Icon inside the outgoing call button */
.cometchat-outgoing-call__button .cometchat-button .cometchat-button__icon {
    -webkit-mask-size: contain;
    width: 24px;
    height: 24px;
    background: var(--cometchat-static-white);
}

/* Media query for screens with a max width of 600px */
@media (max-width: 600px) {

    /* Font size for avatar text in smaller screens */
    .cometchat-outgoing-call__avatar .cometchat-avatar__text {
        font: 700 50px/60px var(--cometchat-font-family);
    }

    /* Avatar size adjustment for smaller screens */
    .cometchat-outgoing-call .cometchat-outgoing-call__avatar .cometchat .cometchat-avatar {
        width: 120px;
        height: 120px;
    }

    /* Font size adjustment for title in smaller screens */
    .cometchat-outgoing-call__title {
        font: var(--cometchat-font-heading1-bold);
    }

    /* Font size adjustment for subtitle in smaller screens */
    .cometchat-outgoing-call__subtitle {
        font: var(--cometchat-font-body-regular);
    }

    /* Size and border radius adjustment for the container in smaller screens */
    .cometchat-outgoing-call {
        height: 100%;
        width: 100%;
        border-radius: 0;
    }

}