/* The main container for the video bubble, setting up basic layout, width,
   padding, and flex properties for alignment and justification */
.cometchat-video-bubble {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: var(--cometchat-radius-3, 12px);
    max-width: 250px;
    max-height: 350px;
    overflow: hidden;
    width: auto;
    height: auto;
    cursor: pointer;
}

/* Defines the body of the video bubble, ensuring the video or media inside
   takes up the full available space with inherited border radius */
.cometchat-video-bubble__body {
    margin: 0;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

/* Specific styling for incoming video bubbles, giving it a background color
   for differentiation */
.cometchat-video-bubble-incoming {
    background: var(--cometchat-neutral-color-300);
}

/* Specific styling for outgoing video bubbles, using the primary color as
   the background to distinguish it from incoming bubbles */
.cometchat-video-bubble-outgoing {
    background: var(--cometchat-primary-color);
}