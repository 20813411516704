
.cometchat-groups {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  flex-shrink: 0;
  background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-groups__empty-state-view {
  display: flex;
  flex-direction: column;
}

.cometchat-groups__empty-state-view,
.cometchat-groups__error-state-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: center;
  background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-groups__empty-state-view-body,
.cometchat-groups__error-state-view-body {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding, 2px);
}

.cometchat-groups__empty-state-view-body-title,
.cometchat-groups__error-state-view-body-title {
  width: 280px;
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  font: var(--cometchat-font-heading3-bold);
  font-style: normal;
}

.cometchat-groups__empty-state-view-body-description,
.cometchat-groups__error-state-view-body-description {
  width: 280px;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-groups__shimmer {
  width: 100%;
  height: 100%;
  background: var(--cometchat-background-color-01, #FFF);

}

.cometchat-groups__shimmer-item {
  width: 100%;
  height: 72px;
  display: flex;
  width: 100%;
  min-width: 240px;
  max-width: 1440px;
  padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
  align-items: center;
  gap: 12px;
}

.cometchat-groups__shimmer-item-avatar {
  display: flex;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: var(--cometchat-radius-max,1000px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
}

.cometchat-groups__shimmer-item-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-groups__shimmer-item-body-title {
  width: 50%;
  height: 22px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 1.5s infinite linear;
}

.cometchat-groups__shimmer-item-body-subtitle {
  width:25%;
  height: 12px;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(
    --cometchat-shimmer-gradient-color,
    linear-gradient(90deg, #e0e0e0 0%, #eee 100%)
  );
  animation: shimmerAnimation 1.5s infinite linear;

}

@keyframes shimmerAnimation {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
  .cometchat-groups__list-item {
    cursor: pointer;
  }

  .cometchat-groups__list-item-active .cometchat-list-item{
    background-color:var(--cometchat-white-pressed,#E8E8E8)
  }

  .cometchat-groups__list-item-password .cometchat-list-item__status{
    width: 14px;
    height: 14px;
    margin-right: -14px;
    position: relative;
    top: 20px;
    right: 15px;
    border-radius: var(--cometchat-radius-max, 1000px);
    min-width: 14px;
    border: 2px solid var(--cometchat-neutral-color-50, #FFF);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cometchat-success-color,#09C26F);
  }

  .cometchat-groups__list-item-private .cometchat-list-item__status{
    width: 14px;
    height: 14px;
    margin-right: -14px;
    position: relative;
    top: 20px;
    right: 15px;
    border-radius: var(--cometchat-radius-max, 1000px);
    min-width: 14px;
    border: 2px solid var(--cometchat-neutral-color-50, #FFF);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cometchat-warning-color,#FFAB00);
  }

  .cometchat-groups__list-item-password .cometchat-list-item__status-icon {
    -webkit-mask: url("../../assets/lock.svg") center center no-repeat;
    mask: url("../../assets/lock.svg") center center no-repeat;
    height: 8px;
    width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    border: none;
    align-self: center;  
    background:var(--cometchat-static-white,#FFF);
  }

  .cometchat-groups__list-item-private .cometchat-list-item__status-icon {
    -webkit-mask: url("../../assets/shield.svg") center center no-repeat;
    mask: url("../../assets/shield.svg") center center no-repeat;
    height: 8px;
    width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    border: none;
    align-self: center;
    background:var(--cometchat-static-white,#FFF);
  }

    .cometchat-groups__subtitle {
      overflow: hidden;
      color: var(--cometchat-text-color-secondary, #727272);
      text-overflow: ellipsis;
      white-space: nowrap;
      font: var(--cometchat-font-body-regular);
      font-style: normal;
    }

  .cometchat-groups__list-item .cometchat-avatar {
    width: 40px;
    height: 40px;
  }
  
  .cometchat-groups__list-item .cometchat-list-item__leading-view {
    width: 40px;
    height: 40px;
  }

  .cometchat-groups .cometchat-list-item__body {
    padding: 0px;
  }
