.cometchat-link-bubble {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  border-radius: var(--cometchat-radius-3, 12px);
  width: 240px;
  max-width: 240px;

}

.cometchat-link-bubble-incoming {
  background: var(--cometchat-neutral-color-300, #E8E8E8);
}

.cometchat-link-bubble-outgoing {
  background: var(--cometchat-primary-color, #6852D6);
}


.cometchat-link-bubble__preview-image {
  height: 50%;
  width: 100%;
  cursor: pointer;
  align-self: stretch;
  background: var(--cometchat-static-black, #0A0914);
  border-radius: var(--cometchat-radius-3, 12px) var(--cometchat-radius-3, 12px) 0px 0px;
}

.cometchat-link-bubble__preview-body {
  display: flex;
  padding: var(--cometchat-padding-2, 8px);
  gap: var(--cometchat-padding-1, 4px);
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px 0px var(--cometchat-radius-2) var(--cometchat-radius-2);
}

.cometchat-link-bubble__preview-body-rounded {
  border-radius: var(--cometchat-radius-2);
}

.cometchat-link-bubble-incoming .cometchat-link-bubble__preview-body {
  background: var(--cometchat-neutral-color-400, #DCDCDC);
}

.cometchat-link-bubble-outgoing .cometchat-link-bubble__preview-body {
  background: var(--cometchat-extended-primary-color-900, #5D49BE);
}

.cometchat-link-bubble__preview-body-content-title {
  font: var(--cometchat-font-body-bold);
  font-style: normal;
}

.cometchat-link-bubble-incoming .cometchat-link-bubble__preview-body-content-title {
  color: var(--cometchat-neutral-color-900, #141414);
}

.cometchat-link-bubble-outgoing .cometchat-link-bubble__preview-body-content-title {
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-link-bubble__preview-body-content-description {
  font: var(--cometchat-font-caption1-regular);
  font-style: normal;
}

.cometchat-link-bubble-incoming .cometchat-link-bubble__preview-body-content-title {
  color: var(--cometchat-neutral-color-900, #141414);
}

.cometchat-link-bubble-outgoing .cometchat-link-bubble__preview-body-content-description {
  color: var(--cometchat-static-white, #FFF);

}

.cometchat-link-bubble__preview-body-content-link {
  font: var(--cometchat-font-caption1-regular);
  font-style: normal;
  opacity: 0.6;
  cursor: pointer;
}

.cometchat-link-bubble-incoming .cometchat-link-bubble__preview-body-content-link {
  color: var(--cometchat-neutral-color-900, #141414);
}

.cometchat-link-bubble-outgoing .cometchat-link-bubble__preview-body-content-link {
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-link-bubble__body {
  width: 100%;
}
.cometchat-link-bubble__body .cometchat-url{
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-link-bubble-incoming .cometchat-link-bubble__body {
  color: var(--cometchat-info-color, #0B7BEA);
}

.cometchat-link-bubble-outgoing .cometchat-link-bubble__body {
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-link-bubble__preview-body-fav-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: var(--cometchat-radius-1, 4px);
}


.cometchat-link-bubble__preview-body-content {
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--cometchat-padding-1, 4px);
}