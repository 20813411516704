.cometchat-radiobutton {
    display: inline-flex;
    align-items: center;
    position: relative;

}

.cometchat-radiobutton__label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.cometchat-radiobutton__selected {
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max, 1000px);
    border: 1.5px solid var(--cometchat-border-color-default, #E8E8E8);
}

.cometchat-radiobutton:hover .cometchat-radiobutton__selected {
    border: 1.5px solid var(--cometchat-border-color-dark, #DCDCDC);
    cursor: pointer;
}

.cometchat-radiobutton input[type="radio"]:checked+.cometchat-radiobutton__selected {
    border: 1.5px solid var(--cometchat-primary-color, #6852D6);
}

.cometchat-radiobutton input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.cometchat-radiobutton__selected::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    border-radius: var(--cometchat-radius-max, 1000px);
    background-color: var(--cometchat-primary-color, #6852D6);
    transform: translate(-50%, -50%);
    display: none;
}

.cometchat-radiobutton input[type="radio"]:checked+.cometchat-radiobutton__selected::after {
    display: block;
}

.cometchat-radiobutton__label {
    display: flex;
    align-items: center;
    gap: var(--cometchat-radius-2, 8px);
    color: var(--cometchat-text-color-secondary);
    font: var(--cometchat-font-body-regular);
}